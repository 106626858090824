// import { useState, useEffect } from "react";
// import axios from "axios";

// const Income = () => {
//   const [depositData, setDepositData] = useState<any>([]);
//   const [status, setStatus] = useState(false);
//    const [filteredData, setFilteredData] = useState<any[]>([]); // State for filtered deposit data based on search
//   const [searchTerm, setSearchTerm] = useState(''); // State for the search term
//   const [editIndex, setEditIndex] = useState<number | null>(null); // Track which row is being edited
//   const [editValue, setEditValue] = useState(''); 

//   useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     axios
//       .get(`https://api.growwincapital.com/api/income/getIncomeAll`, { headers })
//       .then((response) => {
//         console.log(response.data.Referal);
//         const deposit: any = Object.values(response.data.Income);
//         setDepositData(deposit);
//         setFilteredData(deposit); // Initially, filtered data is the same as all data

//       })
//       .catch((error) => {
//         console.log("no error " + error);
//       });
//   }, [status]);

//   useEffect(() => {
//     // Filter depositData based on the searchTerm whenever the searchTerm or depositData changes
//     const filtered = depositData.filter((item:any) =>
//       item.email.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredData(filtered);
//   }, [searchTerm, depositData]);
//   const handleUpdateIncome = (index: number) => {
//     // Calculate the original index in the non-reversed array for filteredData
//     const originalIndexFiltered = filteredData.length - 1 - index;
//     const itemFiltered = filteredData[originalIndexFiltered];
  
//     // Find the same item in depositData to update
//     const originalIndexDeposit = depositData.findIndex((depositItem: { email: any; createdAt: any; }) => depositItem.email === itemFiltered.email && depositItem.createdAt === itemFiltered.createdAt);
//     const itemDeposit = depositData[originalIndexDeposit];
  
//     axios.post('https://api.growwincapital.com/api/income/updateIncome', { email: itemFiltered.email, income: editValue, date: itemFiltered.createdAt })
//       .then(response => {
//         // Update filteredData with the new income
//         const newDataFiltered = [...filteredData];
//         newDataFiltered[originalIndexFiltered].income = editValue; // Update the income in the filtered data
//         setFilteredData(newDataFiltered);
  
//         // Update depositData with the new income if the item exists in it
//         if (itemDeposit && originalIndexDeposit !== -1) {
//           const newDataDeposit = [...depositData];
//           newDataDeposit[originalIndexDeposit].income = editValue; // Update the income in the original data
//           setDepositData(newDataDeposit);
//         }
  
//         setEditIndex(null); // Reset edit mode
//       })
//       .catch(error => {
//         console.error('Error updating income:', error);
//       });
//   };
  
//   return (
//     <div>
//       <h1 className="text-2xl font-bold mb-4">Self Income</h1>
//         <input
//         type="text"
//         placeholder="Search by user email..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="mb-4 p-2 border rounded"
//       />
      
//       <div className="h-[690px] overflow-scroll">
//         <table>
//           <thead>
//             <tr>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Update Income</th>
//             </tr>
//           </thead>
//           <tbody>
//             {[...filteredData].reverse().map((request: any, index: any) => (
//               <tr key={index + 1}>
//                 <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.email} </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//   {Number(request.income).toFixed(2)}
// </td>
// <td className="py-3 px-8 border-b border-gray-200">
//               {editIndex === index ? (
//                 <>
//                   <input
//                     type="text"
//                     value={editValue}
//                     onChange={(e) => setEditValue(e.target.value)}
//                     className="mb-4 p-2 border rounded"
//                   />
//                   <button
//                     onClick={() => handleUpdateIncome(index)}
//                     className="p-2 border rounded bg-blue-500 text-white"
//                   >
//                     Update
//                   </button>
//                 </>
//               ) : (
//                 <button
//                   onClick={() => { setEditIndex(index); setEditValue(request.income.toString()); }}
//                   className="p-2 border rounded bg-green-500 text-white"
//                 >
//                   Edit
//                 </button>
//               )}
//             </td>

//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Income;

import { useState, useEffect } from "react";
import axios from "axios";

interface IncomeData {
  _id: string;
  email: string;
  income: number;
  createdAt: string;
}

const Income: React.FC = () => {
  const [incomeData, setIncomeData] = useState<IncomeData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState("");

  useEffect(() => {
    fetchIncomeData();
  }, []);

  const fetchIncomeData = async () => {
    try {
      const response = await axios.get("https://api.growwincapital.com/api/income/getIncomeAll");
  
      if (response.data.Income) {
        const sortedData = Object.values(response.data.Income) as IncomeData[];
  
        sortedData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
        setIncomeData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching income data:", error);
    }
  };
  

  const filteredIncomeData = incomeData.filter((item) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUpdateIncome = async (index: number) => {
    const item = filteredIncomeData[index];

    try {
      await axios.post("https://api.growwincapital.com/api/income/updateIncome", {
        email: item.email,
        income: editValue,
        date: item.createdAt,
      });

      // Update the local state with the new income value
      const updatedData = incomeData.map((entry) =>
        entry._id === item._id ? { ...entry, income: parseFloat(editValue) } : entry
      );
      setIncomeData(updatedData);
      setEditIndex(null);
    } catch (error) {
      console.error("Error updating income:", error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Self Income</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by Email..."
        className="border rounded-lg p-3 w-full mb-6 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Income List */}
      <div className="space-y-6">
        {filteredIncomeData.map((income, index) => (
          <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-semibold text-gray-800">{income.email}</h2>
              <span className="px-3 py-1 rounded-full text-xs font-semibold bg-blue-100 text-blue-700">
                Income Record
              </span>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-3">
              <p className="text-gray-600">
                <strong>Income Date:</strong> {new Date(income.createdAt).toLocaleDateString("en-GB")}
              </p>
              <p className="text-gray-600">
  <strong>Amount:</strong> ${Number(income.income || 0).toFixed(2)}
</p>

              <div>
                {editIndex === index ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      className="border p-2 rounded-lg w-24"
                    />
                    <button
                      onClick={() => handleUpdateIncome(index)}
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      setEditIndex(index);
                      setEditValue(income.income.toString());
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Income;
