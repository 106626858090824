

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

interface KYCData {
  _id: string;
  email: string;
  completionPercentage: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  aadhaarNumber?: string;
  panNumber?: string;
  country?: string;
  address?: string;
}

interface ImageData {
  url: string | null;
  title: string;
  type: "aadhaar" | "pan";
  side: "front" | "back";
}

const KYCDataComponent: React.FC = () => {
  const [kycData, setKYCData] = useState<KYCData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [showPopup, setShowPopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [selectedKYC, setSelectedKYC] = useState<KYCData | null>(null);
  const [actionType, setActionType] = useState<"Accept" | "Reject" | null>(null);
  const [loadingImages, setLoadingImages] = useState(false);
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageZoom, setImageZoom] = useState(1);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // Use a single state for all images
  const [images, setImages] = useState<ImageData[]>([]);

  const fetchKYCData = async () => {
    try {
      const response = await axios.get("https://api.growwincapital.com/api/kyc/processing");
      
      const sortedByDate = response.data.data.sort(
        (a: KYCData, b: KYCData) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
  
      const statusOrder: Record<string, number> = { processing: 1, verified: 2, rejected: 3 };
  
      const finalSortedData = sortedByDate.sort((a: { status: string; }, b: { status: string; }) => {
        const statusA = a.status?.toLowerCase() || "unknown";
        const statusB = b.status?.toLowerCase() || "unknown";
  
        return (statusOrder[statusA] || 4) - (statusOrder[statusB] || 4);
      });
  
      setKYCData(finalSortedData);
    } catch (error) {
      console.error("Error fetching KYC data:", error);
    }
  };
  
  useEffect(() => {
    fetchKYCData();
  }, []);

  useEffect(() => {
    // Reset zoom and position when opening a new image
    if (imageGalleryOpen) {
      setImageZoom(1);
      setImagePosition({ x: 0, y: 0 });
    }
  }, [imageGalleryOpen, currentImageIndex]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(event.target.value);
  };

  const filteredKYCData = kycData.filter((item) =>
    (item.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (statusFilter === "All" || item.status.toLowerCase() === statusFilter.toLowerCase())
  );


  const fetchImages = useCallback(async (item: KYCData) => {
    if (!item || !item.email) return;
    
    setLoadingImages(true);
    setImages([]); // Clear previous images
    
    const newImages: ImageData[] = [];
    
    try {
      // Fetch Aadhaar/National ID images
      const frontResponse = await axios.get(
        `https://api.growwincapital.com/api/kyc/image/${item.email}/front`,
        { responseType: 'arraybuffer' } // Request binary data
      );
      
      const backResponse = await axios.get(
        `https://api.growwincapital.com/api/kyc/image/${item.email}/back`,
        { responseType: 'arraybuffer' } // Request binary data
      );
      
      console.log("Front response:", frontResponse);
      console.log("Back response:", backResponse);
      
      const isIndian = item.country === "India";
      
      // Convert binary data to data URL
      if (frontResponse.data) {
        const frontBlob = new Blob([frontResponse.data]);
        const frontUrl = URL.createObjectURL(frontBlob);
        newImages.push({
          url: frontUrl,
          title: isIndian ? "Aadhaar Front" : "National ID Front",
          type: "aadhaar",
          side: "front"
        });
      }
      
      if (backResponse.data) {
        const backBlob = new Blob([backResponse.data]);
        const backUrl = URL.createObjectURL(backBlob);
        newImages.push({
          url: backUrl,
          title: isIndian ? "Aadhaar Back" : "National ID Back",
          type: "aadhaar",
          side: "back"
        });
      }
      
      // Fetch PAN images if country is India
      if (isIndian && item.panNumber) {
        try {
          const panFrontResponse = await axios.get(
            `https://api.growwincapital.com/api/kyc/panimage/${item.email}/front`,
            { responseType: 'arraybuffer' } // Request binary data
          );
          
          const panBackResponse = await axios.get(
            `https://api.growwincapital.com/api/kyc/panimage/${item.email}/back`,
            { responseType: 'arraybuffer' } // Request binary data
          );
          
          console.log("PAN Front:", panFrontResponse);
          console.log("PAN Back:", panBackResponse);
          
          if (panFrontResponse.data) {
            const panFrontBlob = new Blob([panFrontResponse.data]);
            const panFrontUrl = URL.createObjectURL(panFrontBlob);
            newImages.push({
              url: panFrontUrl,
              title: "PAN Front",
              type: "pan",
              side: "front"
            });
          }
          
          if (panBackResponse.data) {
            const panBackBlob = new Blob([panBackResponse.data]);
            const panBackUrl = URL.createObjectURL(panBackBlob);
            newImages.push({
              url: panBackUrl,
              title: "PAN Back",
              type: "pan",
              side: "back"
            });
          }
        } catch (panError) {
          console.error("Error fetching PAN images:", panError);
        }
      }
    } catch (error) {
      console.error("Error fetching ID images: ", error);
    } finally {
      console.log("Final images array:", newImages);
      setImages(newImages);
      setLoadingImages(false);
    }
  }, []);
  useEffect(() => {
    return () => {
      // Clean up object URLs when component unmounts
      images.forEach(image => {
        if (image.url) {
          URL.revokeObjectURL(image.url);
        }
      });
    };
  }, [images]);
  const handleAction = async (item: KYCData, action: "Accept" | "Reject") => {
    setSelectedKYC(item);
    setActionType(action);
    setShowPopup(true);
    fetchImages(item);
  };

  const handleViewAadhaar = (item: KYCData) => {
    setSelectedKYC(item);
    setShowViewPopup(true);
    fetchImages(item);
  };

  const openImageGallery = (index: number) => {
    setCurrentImageIndex(index);
    setImageGalleryOpen(true);
  };

  const handleConfirmAction = async () => {
    if (!selectedKYC || !actionType) return;
    const status = actionType === "Accept" ? "verified" : "rejected";

    try {
      await axios.patch(`https://api.growwincapital.com/api/kyc/verify/${selectedKYC.email}`, { status });
      setShowPopup(false);
      fetchKYCData(); 
    } catch (error) {
      console.error("Error updating KYC status: ", error);
    }
  };

  const handleZoomIn = () => {
    setImageZoom(prev => Math.min(prev + 0.25, 3));
  };

  const handleZoomOut = () => {
    setImageZoom(prev => Math.max(prev - 0.25, 0.5));
  };

  const handleResetZoom = () => {
    setImageZoom(1);
    setImagePosition({ x: 0, y: 0 });
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0) { // Left mouse button
      setIsDragging(true);
      setDragStart({
        x: e.clientX - imagePosition.x,
        y: e.clientY - imagePosition.y
      });
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      setImagePosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    if (e.deltaY < 0) {
      // Scroll up - zoom in
      setImageZoom(prev => Math.min(prev + 0.1, 3));
    } else {
      // Scroll down - zoom out
      setImageZoom(prev => Math.max(prev - 0.1, 0.5));
    }
  };

  const nextImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };
  
  const prevImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
  };

  // Get current image
  const currentImage = images[currentImageIndex] || null;
  
  // Handle closing all popups
  const closeAllPopups = () => {
    setShowPopup(false);
    setShowViewPopup(false);
    setImageGalleryOpen(false);
    setImageZoom(1);
    setImagePosition({ x: 0, y: 0 });
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">KYC Requests</h1>

      {/* Search & Filter Controls */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <input
          type="text"
          placeholder="Search by Email..."
          className="border rounded-lg p-3 w-full md:w-1/2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <select
          className="border rounded-lg p-3 w-full md:w-1/4 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={statusFilter}
          onChange={handleStatusFilterChange}
        >
          <option value="All">All</option>
          <option value="processing">Processing</option>
          <option value="verified">Verified</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>

      {/* KYC List */}
      <div className="space-y-6">
        {filteredKYCData.length > 0 ? (
          filteredKYCData.map((item, index) => (
            <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold text-gray-800">{item.email}</h2>
                <span
                  className={`px-3 py-1 rounded-full text-xs font-semibold ${
                    item.status.toLowerCase() === "verified"
                      ? "bg-green-100 text-green-700"
                      : item.status.toLowerCase() === "processing"
                      ? "bg-yellow-100 text-yellow-700"
                      : "bg-red-100 text-red-700"
                  }`}
                >
                  {item.status}
                </span>
              </div>

              <div className="mt-3">
                <p className="text-gray-600"><strong>Completion:</strong> {item.completionPercentage}%</p>
                {item.country && <p className="text-gray-600"><strong>Country:</strong> {item.country}</p>}
                {item.country === "India" ? (
                  <>
                    <p className="text-gray-600"><strong>Aadhaar No.:</strong> {item.aadhaarNumber || "Not Provided"}</p>
                    {item.panNumber && <p className="text-gray-600"><strong>PAN No.:</strong> {item.panNumber}</p>}
                  </>
                ) : (
                  <p className="text-gray-600"><strong>National ID:</strong> {item.aadhaarNumber || "Not Provided"}</p>
                )}
                {item.address && <p className="text-gray-600"><strong>Address:</strong> {item.address}</p>}
              </div>

              {/* Action Buttons */}
              <div className="mt-4 flex space-x-4">
                {Number(item.completionPercentage) === 100 ? (
                  <button
                    className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white"
                    onClick={() => handleViewAadhaar(item)}
                  >
                    View ID Documents
                  </button>
                ) : Number(item.completionPercentage) === 50 ? (
                  <span className="text-red-600 font-semibold">Not Submitted</span>
                ) : (
                  item.status === "processing" && (
                    <>
                      <button
                        onClick={() => handleAction(item, "Accept")}
                        className="text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => handleAction(item, "Reject")}
                        className="text-white bg-red-500 hover:bg-red-600 px-4 py-2 rounded"
                      >
                        Reject
                      </button>
                    </>
                  )
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">No KYC requests found.</p>
        )}
      </div>

      {/* Action Popup with Images */}
      {showPopup && selectedKYC && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-screen overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{actionType} KYC for {selectedKYC.email}</h2>
              <button 
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShowPopup(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            {loadingImages ? (
              <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                {images.map((image, index) => (
                  <div key={index} className="group relative">
                    <h3 className="font-semibold mb-2">{image.title}</h3>
                    <div className="relative overflow-hidden rounded-lg shadow-md transition-all duration-300 group-hover:shadow-lg">
                    <div className="w-full h-40 bg-gray-100 flex items-center justify-center">
  {image.url ? (
    <img
      src={image.url}
      alt={image.title}
      onClick={() => openImageGallery(index)}
      className="w-full h-40 object-contain cursor-pointer transition-transform duration-300 group-hover:scale-105"
      onError={(e) => {
        console.log("Image failed to load:", image.url);
        (e.target as HTMLImageElement).src = "/placeholder-image.jpg";
        // Add a class to show there was an error
        (e.target as HTMLImageElement).classList.add("image-error");
      }}
    />
  ) : (
    <div className="text-gray-400 text-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
      <p>No image available</p>
    </div>
  )}
</div>
                      
                      {image.url && (
                        <div className="absolute inset-0 bg-black bg-opacity-0 flex items-center justify-center transition-all duration-300 group-hover:bg-opacity-20">
                          <button 
                            className="bg-white text-gray-800 rounded-full p-2 opacity-0 transform scale-50 transition-all duration-300 group-hover:opacity-100 group-hover:scale-100"
                            onClick={() => openImageGallery(index)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            
            <div className="flex justify-end gap-4">
              <button className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded-xl" onClick={() => setShowPopup(false)}>
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-xl text-white ${actionType === "Accept" ? "bg-green-600 hover:bg-green-700" : "bg-red-400 hover:bg-red-500"}`}
                onClick={handleConfirmAction}
              >
                Confirm {actionType}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* View Documents Popup */}
      {showViewPopup && selectedKYC && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-screen overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Document Verification: {selectedKYC.email}</h2>
              <button 
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShowViewPopup(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            {loadingImages ? (
              <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                {images.map((image, index) => (
                  <div key={index} className="group relative">
                    <h3 className="font-semibold mb-2">{image.title}</h3>
                    <div className="relative overflow-hidden rounded-lg shadow-md transition-all duration-300 group-hover:shadow-lg">
                    <div className="w-full h-40 bg-gray-100 flex items-center justify-center">
  {image.url ? (
    <img
      src={image.url}
      alt={image.title}
      onClick={() => openImageGallery(index)}
      className="w-full h-40 object-contain cursor-pointer transition-transform duration-300 group-hover:scale-105"
      onError={(e) => {
        console.log("Image failed to load:", image.url);
        (e.target as HTMLImageElement).src = "/placeholder-image.jpg";
        // Add a class to show there was an error
        (e.target as HTMLImageElement).classList.add("image-error");
      }}
    />
  ) : (
    <div className="text-gray-400 text-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
      <p>No image available</p>
    </div>
  )}
</div>
                      
                      {image.url && (
                        <div className="absolute inset-0 bg-black bg-opacity-0 flex items-center justify-center transition-all duration-300 group-hover:bg-opacity-20">
                          <button 
                            className="bg-white text-gray-800 rounded-full p-2 opacity-0 transform scale-50 transition-all duration-300 group-hover:opacity-100 group-hover:scale-100"
                            onClick={() => openImageGallery(index)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Advanced Image Gallery */}
      {imageGalleryOpen && currentImage && (
        <div 
          className="fixed inset-0 bg-black z-50 flex flex-col" 
          onClick={closeAllPopups}
        >
          {/* Top toolbar */}
          <div 
            className="flex justify-between items-center p-4 bg-black bg-opacity-80 text-white" 
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-lg font-medium">
              {currentImage.title}
              <span className="ml-2 text-sm text-gray-400">
                ({currentImageIndex + 1}/{images.length})
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <button 
                className="p-2 rounded-full hover:bg-gray-700 transition-colors"
                onClick={handleZoomOut}
                title="Zoom Out"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              <span className="text-sm">{Math.round(imageZoom * 100)}%</span>
              <button 
                className="p-2 rounded-full hover:bg-gray-700 transition-colors"
                onClick={handleZoomIn}
                title="Zoom In"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              <button 
                className="p-2 rounded-full hover:bg-gray-700 transition-colors"
                onClick={handleResetZoom}
                title="Reset Zoom"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              </button>
              <button 
                className="p-2 rounded-full hover:bg-gray-700 transition-colors"
                onClick={closeAllPopups}
                title="Close"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          {/* Image container */}
          <div 
            className="relative flex-1 flex items-center justify-center overflow-hidden"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onWheel={handleWheel}
          >
           {currentImage.url ? (
  <img
    src={currentImage.url}
    alt={currentImage.title}
    style={{
      transform: `translate(${imagePosition.x}px, ${imagePosition.y}px) scale(${imageZoom})`,
      transition: isDragging ? 'none' : 'transform 0.1s',
      cursor: isDragging ? 'grabbing' : 'grab'
    }}
    className="max-h-full max-w-full object-contain"
    draggable="false"
    onError={(e) => {
      console.log("Gallery image failed to load:", currentImage.url);
      (e.target as HTMLImageElement).src = "/placeholder-image.jpg";
    }}
  />
) : (
  <div className="text-white text-center">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
    <p className="text-xl">Image not available</p>
  </div>
)}

            {/* Navigation arrows */}
            {images.length > 1 && (
              <>
                <button 
                  className="absolute left-4 p-2 rounded-full bg-black bg-opacity-50 text-white hover:bg-opacity-70 transition-all transform -translate-y-1/2 top-1/2"
                  onClick={(e) => { e.stopPropagation(); prevImage(); }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                  </button>
                <button 
                  className="absolute right-4 p-2 rounded-full bg-black bg-opacity-50 text-white hover:bg-opacity-70 transition-all transform -translate-y-1/2 top-1/2"
                  onClick={(e) => { e.stopPropagation(); nextImage(); }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default KYCDataComponent;