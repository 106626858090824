// import { useState, useEffect } from "react";
// import axios from "axios";

// const Deposits = () => {
//   const [depositData, setDepositData] = useState<any>([]);
//   const [status, setStatus] = useState(false);

//   useEffect(() => {
//     axios
//       .get(`https://api.growwincapital.com/api/deposit/getDeposit`, {
//         headers: {
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "*",
//           "Access-Control-Allow-Methods": "*",
//         },
//       })
//       .then((response) => {
//         console.log("Deposit Data:", response.data.Deposit);
//         // Convert object to array & sort by date (latest first)
//         const sortedDeposits = Object.values(response.data.Deposit).sort(
//           (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
//         );
//         setDepositData(sortedDeposits);
//       })
//       .catch((error) => {
//         console.error("Error fetching deposits:", error);
//       });
//   }, [status]);

//   return (
//     <div className="max-w-6xl mx-auto p-8">
//       <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Deposit Requests</h1>

//       <div className="space-y-6">
//         {depositData.map((request: any, index: number) => (
//           <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
//             <div className="flex justify-between items-center mb-2">
//               <h2 className="text-lg font-semibold text-gray-800">{request.email}</h2>
//               <span
//                 className={`px-3 py-1 rounded-full text-xs font-semibold ${
//                   request.status.toLowerCase() === "success"
//                     ? "bg-green-100 text-green-700"
//                     : request.status.toLowerCase() === "pending"
//                     ? "bg-yellow-100 text-yellow-700"
//                     : "bg-red-100 text-red-700"
//                 }`}
//               >
//                 {request.status}
//               </span>
//             </div>

//             <div className="mt-3">
//               <p className="text-gray-600">
//                 <strong>Amount:</strong> <span className="text-green-600 font-bold">${request.amount}</span>
//               </p>
//               <p className="text-gray-600">
//                 <strong>Time:</strong> {new Date(request.createdAt).toLocaleString()}
//               </p>
//               <p className="text-gray-600">
//                 <strong>Chain:</strong> {request.chain}
//               </p>
//               <p className="text-gray-600 truncate">
//                 <strong>Order ID:</strong> {request.orderid}
//               </p>
//               <p className="text-gray-600 truncate">
//                 <strong>Deposit Address:</strong> {request.deposit_address}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Deposits;
import { useState, useEffect } from "react";
import axios from "axios";

const Deposits = () => {
  const [depositData, setDepositData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");

  useEffect(() => {
    axios
      .get(`https://api.growwincapital.com/api/deposit/getDeposit`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      })
      .then((response) => {
        console.log("Deposit Data:", response.data.Deposit);
        const sortedDeposits = Object.values(response.data.Deposit).sort(
          (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setDepositData(sortedDeposits);
        setFilteredData(sortedDeposits);
      })
      .catch((error) => {
        console.error("Error fetching deposits:", error);
      });
  }, []);

  // Handle Search & Filter
  useEffect(() => {
    let filtered = depositData;

    if (searchTerm) {
      filtered = filtered.filter(
        (item: any) =>
          item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.orderid.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (statusFilter !== "All") {
      filtered = filtered.filter((item: any) => item.status.toLowerCase() === statusFilter.toLowerCase());
    }

    setFilteredData(filtered);
  }, [searchTerm, statusFilter, depositData]);

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Deposit Requests</h1>

      {/* Search & Filter Controls */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <input
          type="text"
          placeholder="Search by Email or Order ID..."
          className="border rounded-lg p-3 w-full md:w-1/2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <select
          className="border rounded-lg p-3 w-full md:w-1/4 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Success">Success</option>
          <option value="Pending">Pending</option>
          <option value="Rejected">Rejected</option>
        </select>
      </div>

      {/* Deposit List */}
      <div className="space-y-6">
        {filteredData.length > 0 ? (
          filteredData.map((request: any, index: number) => (
            <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold text-gray-800">{request.email}</h2>
                <span
                  className={`px-3 py-1 rounded-full text-xs font-semibold ${
                    request.status.toLowerCase() === "success"
                      ? "bg-green-100 text-green-700"
                      : request.status.toLowerCase() === "pending"
                      ? "bg-yellow-100 text-yellow-700"
                      : "bg-red-100 text-red-700"
                  }`}
                >
                  {request.status}
                </span>
              </div>

              <div className="mt-3">
                <p className="text-gray-600">
                  <strong>Amount:</strong> <span className="text-green-600 font-bold">${request.amount}</span>
                </p>
                <p className="text-gray-600">
                  <strong>Time:</strong> {new Date(request.createdAt).toLocaleString()}
                </p>
                <p className="text-gray-600">
                  <strong>Chain:</strong> {request.chain}
                </p>
                <p className="text-gray-600 truncate">
                  <strong>Order ID:</strong> {request.orderid}
                </p>
                <p className="text-gray-600 truncate">
                  <strong>Deposit Address:</strong> {request.deposit_address}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">No deposits found.</p>
        )}
      </div>
    </div>
  );
};

export default Deposits;
