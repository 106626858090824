import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Interfaces
export interface User {
  _id: string;
  name: string;
  email: string;
  phone?: string;
}

export interface Popup {
  _id: string;
  name: string;
  imageUrl: string;
  isActive: boolean;
  startDate: string;
  endDate: string;
  targetUserIds?: string[];
  createdAt: string;
  updatedAt: string;
}

export interface NotificationPayload {
  userId?: string;
  title: string;
  body: string;
  type?: string;
  data?: Record<string, any>;
}

export interface SpecialOfferPayload {
  title: string;
  body: string;
  offerDetails: {
    offerId: string;
    validUntil: string;
    discountAmount?: string;
    couponCode?: string;
    [key: string]: any;
  };
}

const API_BASE_URL = 'https://api.growwincapital.com';

// API Functions
const fetchUsers = async (page = 1, limit = 20, searchTerm = '') => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/user/getUsers`, {
      params: { page, limit, search: searchTerm }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

const fetchAllPopups = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/popUp/popups`);
    return response.data;
  } catch (error) {
    console.error('Error fetching popups:', error);
    throw error;
  }
};

const createPopup = async (formData: FormData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/popUp/popups`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating popup:', error);
    throw error;
  }
};

const updatePopup = async (id: string, formData: FormData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/popUp/popups/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating popup:', error);
    throw error;
  }
};

const deletePopup = async (id: string) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/api/popUp/popups/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting popup:', error);
    throw error;
  }
};

const togglePopupStatus = async (id: string) => {
  try {
    const response = await axios.patch(`${API_BASE_URL}/api/popUp/popups/${id}/toggle-status`);
    return response.data;
  } catch (error) {
    console.error('Error toggling popup status:', error);
    throw error;
  }
};

const sendUserNotification = async (payload: NotificationPayload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/send-user-notification`, payload);
    return response.data;
  } catch (error) {
    console.error('Error sending user notification:', error);
    throw error;
  }
};

const sendBroadcast = async (payload: NotificationPayload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/send-broadcast`, payload);
    return response.data;
  } catch (error) {
    console.error('Error sending broadcast:', error);
    throw error;
  }
};

const sendSpecialOffer = async (payload: SpecialOfferPayload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/send-special-offer`, payload);
    return response.data;
  } catch (error) {
    console.error('Error sending special offer:', error);
    throw error;
  }
};

const UnifiedManagementPanel: React.FC = () => {
  // Shared state
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [activeModule, setActiveModule] = useState('notifications'); // 'notifications' or 'popups'
  const [searchTerm, setSearchTerm] = useState('');
  const [userPagination, setUserPagination] = useState({
    page: 1,
    limit: 20,
    total: 0,
    totalPages: 0,
  });
  
  // Notification state
  const [activeNotificationTab, setActiveNotificationTab] = useState('general');
  const [notification, setNotification] = useState({
    title: '',
    body: '',
    type: 'general',
    data: {}
  });
  const [specialOffer, setSpecialOffer] = useState({
    offerId: '',
    validUntil: '',
    discountAmount: '',
    couponCode: '',
  });
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [notificationType, setNotificationType] = useState('single'); // 'single', 'multiple', 'all'
  const [showUserSelector, setShowUserSelector] = useState(false);
  
  // Popup state
  const [popups, setPopups] = useState<Popup[]>([]);
  const [isCreatingPopup, setIsCreatingPopup] = useState(false);
  const [isEditingPopup, setIsEditingPopup] = useState(false);
  const [currentPopup, setCurrentPopup] = useState<Popup | null>(null);
  const [popupFormData, setPopupFormData] = useState({
    name: '',
    isActive: false,
    startDate: '',
    endDate: '',
    targetUserIds: [] as string[],
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [popupTargetType, setPopupTargetType] = useState('all'); // 'all' or 'specific'
  const [showPopupUserSelector, setShowPopupUserSelector] = useState(false);
  
  useEffect(() => {
    if (activeModule === 'notifications') {
        setUsers([]); // Reset users before fetching new data
        fetchUserData();
      } else if (activeModule === 'popups') {
      fetchPopupData();
      fetchUserData();
    }
  }, [activeModule, userPagination.page, userPagination.limit, searchTerm]);
  
  // Debounce search term changes
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm) {
        fetchUserData();
      }
    }, 500);
    
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (activeModule === 'notifications') {
      setUserPagination({
        page: 1,
        limit: 20,
        total: 0,
        totalPages: 0,
      });
      fetchUserData();
    }
  }, [activeModule]);
  
  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await fetchUsers(userPagination.page, userPagination.limit, searchTerm);
      
      if (response?.data) {
        setUsers(response.data); // Update users state
      } else {
        setUsers([]); // Ensure it's an empty array if no users are returned
      }
  
      // Update pagination details
      setUserPagination(prevState => ({
        ...prevState,
        total: response.pagination?.totalUsers || 0,
        totalPages: response.pagination?.totalPages || 1,
      }));
  
    } catch (error) {
      console.error('Error fetching users:', error);
      showError('Failed to load users. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const fetchPopupData = async () => {
    setLoading(true);
    try {
      const data = await fetchAllPopups();
      setPopups(data.data || []);
    } catch (error) {
      console.error('Error fetching popups:', error);
      showError('Failed to load popups. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const showSuccess = (message: string) => {
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(''), 3000);
  };
  
  const showError = (message: string) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(''), 3000);
  };
  
  // Notification handlers
  const handleNotificationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNotification({ ...notification, [name]: value });
  };
  
  const handleSpecialOfferChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSpecialOffer({ ...specialOffer, [name]: value });
  };
  
  const handleUserSelection = (userId: string) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };
  
  const handleSendNotification = async () => {
    if (!notification.title || !notification.body) {
      showError('Title and body are required!');
      return;
    }
    
    try {
      if (activeNotificationTab === 'general') {
        if (notificationType === 'single' && selectedUsers.length === 1) {
          await sendUserNotification({
            userId: selectedUsers[0],
            title: notification.title,
            body: notification.body,
            type: notification.type,
          });
          showSuccess('Notification sent successfully to the selected user!');
        } else if (notificationType === 'multiple' && selectedUsers.length > 0) {
          // Send to multiple selected users one by one
          for (const userId of selectedUsers) {
            await sendUserNotification({
              userId,
              title: notification.title,
              body: notification.body,
              type: notification.type,
            });
          }
          showSuccess(`Notification sent successfully to ${selectedUsers.length} users!`);
        } else if (notificationType === 'all') {
          await sendBroadcast({
            title: notification.title,
            body: notification.body,
            type: 'broadcast',
          });
          showSuccess('Broadcast notification sent successfully to all users!');
        } else {
          showError('Please select at least one user!');
        }
      } else if (activeNotificationTab === 'special-offer') {
        if (!specialOffer.offerId || !specialOffer.validUntil) {
          showError('Offer ID and Valid Until date are required!');
          return;
        }
        
        await sendSpecialOffer({
          title: notification.title,
          body: notification.body,
          offerDetails: specialOffer,
        });
        
        showSuccess('Special offer notification sent successfully!');
      }
      
      // Reset form
      setNotification({
        title: '',
        body: '',
        type: 'general',
        data: {}
      });
      setSpecialOffer({
        offerId: '',
        validUntil: '',
        discountAmount: '',
        couponCode: '',
      });
      setSelectedUsers([]);
    } catch (error) {
      console.error('Error sending notification:', error);
      showError('Failed to send notification. Please try again.');
    }
  };
  
  // Popup handlers
  const resetPopupForm = () => {
    setPopupFormData({
      name: '',
      isActive: false,
      startDate: '',
      endDate: '',
      targetUserIds: [],
    });
    setImageFile(null);
    setImagePreview(null);
    setCurrentPopup(null);
    setPopupTargetType('all');
  };
  
  const handlePopupInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setPopupFormData({
      ...popupFormData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  
  const handlePopupImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);
      
      // Create image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleCreatePopup = () => {
    resetPopupForm();
    setIsCreatingPopup(true);
    setIsEditingPopup(false);
  };
  
  const handleEditPopup = (popup: Popup) => {
    setCurrentPopup(popup);
    setPopupFormData({
      name: popup.name,
      isActive: popup.isActive,
      startDate: new Date(popup.startDate).toISOString().split('T')[0],
      endDate: new Date(popup.endDate).toISOString().split('T')[0],
      targetUserIds: popup.targetUserIds || [],
    });
    setImagePreview(`https://api.growwincapital.com/api/popUp/popups/image/${popup._id}`);
    setPopupTargetType(popup.targetUserIds && popup.targetUserIds.length > 0 ? 'specific' : 'all');
    setIsEditingPopup(true);
    setIsCreatingPopup(false);
  };
  
  const handleTogglePopupStatus = async (id: string) => {
    try {
      await togglePopupStatus(id);
      fetchPopupData();
      showSuccess('Popup status updated successfully!');
    } catch (error) {
      console.error('Error toggling popup status:', error);
      showError('Failed to update popup status. Please try again.');
    }
  };
  
  const handleDeletePopup = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this popup?')) {
      try {
        await deletePopup(id);
        fetchPopupData();
        showSuccess('Popup deleted successfully!');
      } catch (error) {
        console.error('Error deleting popup:', error);
        showError('Failed to delete popup. Please try again.');
      }
    }
  };
  
  const handlePopupUserSelection = (userId: string) => {
    const newSelectedUsers = [...popupFormData.targetUserIds];
    
    if (newSelectedUsers.includes(userId)) {
      const index = newSelectedUsers.indexOf(userId);
      newSelectedUsers.splice(index, 1);
    } else {
      newSelectedUsers.push(userId);
    }
    
    setPopupFormData({
      ...popupFormData,
      targetUserIds: newSelectedUsers,
    });
  };
  
  const handleSubmitPopup = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!popupFormData.name || !popupFormData.startDate || !popupFormData.endDate) {
      showError('Please fill in all required fields.');
      return;
    }
    
    if (!imageFile && !isEditingPopup) {
      showError('Please upload an image for the popup.');
      return;
    }
    
    const formPayload = new FormData();
    formPayload.append('name', popupFormData.name);
    formPayload.append('isActive', String(popupFormData.isActive));
    formPayload.append('startDate', popupFormData.startDate);
    formPayload.append('endDate', popupFormData.endDate);
    
    if (popupTargetType === 'specific') {
      formPayload.append('targetUserIds', JSON.stringify(popupFormData.targetUserIds));
    } else {
      formPayload.append('targetUserIds', JSON.stringify([]));
    }
    
    if (imageFile) {
      formPayload.append('popupImage', imageFile);
    }
    
    try {
      if (isEditingPopup && currentPopup) {
        await updatePopup(currentPopup._id, formPayload);
        showSuccess('Popup updated successfully!');
      } else {
        await createPopup(formPayload);
        showSuccess('Popup created successfully!');
      }
      
      fetchPopupData();
      resetPopupForm();
      setIsCreatingPopup(false);
      setIsEditingPopup(false);
    } catch (error) {
      console.error('Error saving popup:', error);
      showError('Failed to save popup. Please try again.');
    }
  };
  
  const handleSelectAllUsers = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map(user => user._id));
    }
  };
  
  const handleSelectAllPopupUsers = () => {
    if (popupFormData.targetUserIds.length === users.length) {
      setPopupFormData({
        ...popupFormData,
        targetUserIds: [],
      });
    } else {
      setPopupFormData({
        ...popupFormData,
        targetUserIds: users.map(user => user._id),
      });
    }
  };
  
  const filteredUsers = users.filter(user => 
    user.name?.toLowerCase().includes(searchTerm.toLowerCase()) || 
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phone?.includes(searchTerm)
  );
  
  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      {/* Success/Error Messages */}
      {successMessage && (
        <div className="mb-4 p-4 bg-green-100 text-green-700 rounded">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
          {errorMessage}
        </div>
      )}
      
      {/* Main Tabs */}
      <div className="mb-6 border-b">
        <div className="flex -mb-px">
          <button
            onClick={() => setActiveModule('notifications')}
            className={`mr-4 py-2 px-4 ${
              activeModule === 'notifications'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-600 hover:text-gray-800'
            }`}
          >
            Notifications
          </button>
          <button
            onClick={() => setActiveModule('popups')}
            className={`py-2 px-4 ${
              activeModule === 'popups'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-600 hover:text-gray-800'
            }`}
          >
            Popup Management
          </button>
        </div>
      </div>
      
      {/* Notification Module */}
      {activeModule === 'notifications' && (
        <div>
          {/* Notification Tabs */}
          <div className="mb-6 border-b">
            <div className="flex -mb-px">
              <button
                onClick={() => setActiveNotificationTab('general')}
                className={`mr-4 py-2 px-4 ${
                  activeNotificationTab === 'general'
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-600 hover:text-gray-800'
                }`}
              >
                General Notification
              </button>
              <button
                onClick={() => setActiveNotificationTab('special-offer')}
                className={`py-2 px-4 ${
                  activeNotificationTab === 'special-offer'
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-600 hover:text-gray-800'
                }`}
              >
                Special Offer
              </button>
            </div>
          </div>
          
          {/* Common Notification Fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Notification Title</label>
            <input
              type="text"
              name="title"
              value={notification.title}
              onChange={handleNotificationChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter notification title"
            />
          </div>
          
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Notification Body</label>
            <textarea
              name="body"
              value={notification.body}
              onChange={handleNotificationChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
              placeholder="Enter notification message"
            />
          </div>
          
          {/* Notification Type - General */}
          {activeNotificationTab === 'general' && (
            <>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Notification Type</label>
                <input
                  type="text"
                  name="type"
                  value={notification.type}
                  onChange={handleNotificationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="E.g., general, promotion, update"
                />
              </div>
              
              <div className="mb-6">
                <div className="flex items-center mb-4">
                  <label className="block text-gray-700 text-sm font-bold mr-4">Send To:</label>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="single"
                        checked={notificationType === 'single'}
                        onChange={() => {
                          setNotificationType('single');
                          setSelectedUsers([]);
                          setShowUserSelector(true);
                        }}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">Single User</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="multiple"
                        checked={notificationType === 'multiple'}
                        onChange={() => {
                          setNotificationType('multiple');
                          setSelectedUsers([]);
                          setShowUserSelector(true);
                        }}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">Multiple Users</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="all"
                        checked={notificationType === 'all'}
                        onChange={() => {
                          setNotificationType('all');
                          setSelectedUsers([]);
                          setShowUserSelector(false);
                        }}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">All Users</span>
                    </label>
                  </div>
                </div>
              </div>
              
              {/* User Selector */}
              {showUserSelector && (
                <div className="mb-6 bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold">Select Users</h3>
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Search users..."
                      />
                      {notificationType === 'multiple' && (
                        <button
                          type="button"
                          onClick={handleSelectAllUsers}
                          className="bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 px-4 rounded"
                        >
                          {selectedUsers.length === users.length ? 'Deselect All' : 'Select All'}
                        </button>
                      )}
                    </div>
                  </div>
                  
                  {loading ? (
  <p className="text-gray-500">Loading users...</p>
) : users.length === 0 ? (
  <p className="text-gray-500">No users found.</p>
) : (
  <div className="max-h-60 overflow-y-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {notificationType === 'multiple' ? 'Select' : 'Select One'}
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Name
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Email
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Phone
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {users.map((user) => (
          <tr key={user._id} className="hover:bg-gray-50">
            <td className="px-6 py-4 whitespace-nowrap">
              <input
                type={notificationType === 'single' ? 'radio' : 'checkbox'}
                name="selectedUser"
                checked={selectedUsers.includes(user._id)}
                onChange={() => {
                  if (notificationType === 'single') {
                    setSelectedUsers([user._id]);
                  } else {
                    handleUserSelection(user._id);
                  }
                }}
                className={notificationType === 'single' ? 'form-radio h-5 w-5 text-blue-600' : 'form-checkbox h-5 w-5 text-blue-600'}
              />
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900">{user.name}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-500">{user.email}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {user.phone || 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

                  {/* Pagination Controls */}
                  <div className="flex items-center justify-between mt-4">
                    <div>
                      <span className="text-sm text-blue-600 font-medium">
                        {selectedUsers.length} user{selectedUsers.length !== 1 ? 's' : ''} selected
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => setUserPagination({...userPagination, page: Math.max(1, userPagination.page - 1)})}
                        disabled={userPagination.page === 1}
                        className={`px-3 py-1 rounded ${userPagination.page === 1 ? 'bg-gray-100 text-gray-400' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                      >
                        Previous
                      </button>
                      <span className="text-sm text-gray-600">
                        Page {userPagination.page} of {userPagination.totalPages}
                      </span>
                      <button
                        onClick={() => setUserPagination({...userPagination, page: Math.min(userPagination.totalPages, userPagination.page + 1)})}
                        disabled={userPagination.page === userPagination.totalPages}
                        className={`px-3 py-1 rounded ${userPagination.page === userPagination.totalPages ? 'bg-gray-100 text-gray-400' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          
          {/* Notification Type - Special Offer */}
          {activeNotificationTab === 'special-offer' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Offer ID</label>
                <input
                  type="text"
                  name="offerId"
                  value={specialOffer.offerId}
                  onChange={handleSpecialOfferChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Unique offer identifier"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Valid Until</label>
                <input
                  type="date"
                  name="validUntil"
                  value={specialOffer.validUntil}
                  onChange={handleSpecialOfferChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Discount Amount</label>
                <input
                  type="text"
                  name="discountAmount"
                  value={specialOffer.discountAmount}
                  onChange={handleSpecialOfferChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="E.g., 20% or $50"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Coupon Code</label>
                <input
                  type="text"
                  name="couponCode"
                  value={specialOffer.couponCode}
                  onChange={handleSpecialOfferChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="E.g., SPECIAL50"
                />
              </div>
            </div>
          )}
          
          {/* Send Button */}
          <div className="flex justify-end">
            <button
              onClick={handleSendNotification}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Notification'}
            </button>
          </div>
        </div>
      )}
      
      {/* Popup Management Module */}
      {activeModule === 'popups' && (
        <div>
          {/* Action Buttons */}
          <div className="mb-6 flex justify-between items-center">
            <h2 className="text-xl font-bold">Popup Management</h2>
            <button
              onClick={handleCreatePopup}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Create New Popup
            </button>
          </div>
          
          {/* Popup Form */}
          {(isCreatingPopup || isEditingPopup) && (
            <div className="mb-8 bg-gray-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">
                {isCreatingPopup ? 'Create New Popup' : 'Edit Popup'}
              </h3>
              
              <form onSubmit={handleSubmitPopup}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Popup Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={popupFormData.name}
                      onChange={handlePopupInputChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Enter popup name"
                      required
                    />
                  </div>
                  
                  <div className="flex items-center h-full pt-6">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="isActive"
                        checked={popupFormData.isActive}
                        onChange={handlePopupInputChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">Active</span>
                    </label>
                  </div>
                  
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Start Date <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={popupFormData.startDate}
                      onChange={handlePopupInputChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                  
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      End Date <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={popupFormData.endDate}
                      onChange={handlePopupInputChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                </div>
                
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Popup Image <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handlePopupImageChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {imagePreview && (
                    <div className="mt-2">
                      <p className="text-sm text-gray-600 mb-1">Preview:</p>
                      <img
                        src={imagePreview}
                        alt="Popup preview"
                        className="h-32 object-contain border rounded"
                      />
                    </div>
                  )}
                </div>
                
                <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Target Users</label>
                  <div className="flex items-center space-x-4 mb-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="all"
                        checked={popupTargetType === 'all'}
                        onChange={() => {
                          setPopupTargetType('all');
                          setShowPopupUserSelector(false);
                          setPopupFormData({
                            ...popupFormData,
                            targetUserIds: [],
                          });
                        }}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">All Users</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="specific"
                        checked={popupTargetType === 'specific'}
                        onChange={() => {
                          setPopupTargetType('specific');
                          setShowPopupUserSelector(true);
                        }}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">Specific Users</span>
                    </label>
                  </div>
                  
                  {/* User Selector for Popups */}
                  {showPopupUserSelector && (
                    <div className="bg-white p-4 rounded-lg border">
                      <div className="flex justify-between items-center mb-4">
                        <h4 className="font-medium">Select Target Users</h4>
                        <div className="flex space-x-2">
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="shadow appearance-none border rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Search users..."
                          />
                          <button
                            type="button"
                            onClick={handleSelectAllPopupUsers}
                            className="bg-gray-200 hover:bg-gray-300 text-gray-800 py-1 px-3 rounded text-sm"
                          >
                            {popupFormData.targetUserIds.length === users.length ? 'Deselect All' : 'Select All'}
                          </button>
                        </div>
                      </div>
                      
                      <div className="max-h-48 overflow-y-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Select
                              </th>
                              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                              </th>
                              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {filteredUsers.map((user) => (
                              <tr key={user._id} className="hover:bg-gray-50">
                                <td className="px-4 py-2 whitespace-nowrap">
                                  <input
                                    type="checkbox"
                                    checked={popupFormData.targetUserIds.includes(user._id)}
                                    onChange={() => handlePopupUserSelection(user._id)}
                                    className="form-checkbox h-4 w-4 text-blue-600"
                                  />
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">{user.name}</div>
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  <div className="text-sm text-gray-500">{user.email}</div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      
                      <div className="mt-2 text-sm text-blue-600 font-medium">
                        {popupFormData.targetUserIds.length} user{popupFormData.targetUserIds.length !== 1 ? 's' : ''} selected
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      resetPopupForm();
                      setIsCreatingPopup(false);
                      setIsEditingPopup(false);
                    }}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : (isEditingPopup ? 'Update Popup' : 'Create Popup')}
                  </button>
                </div>
              </form>
            </div>
          )}
          
          {/* Popup List */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Active Popups</h3>
            {loading ? (
              <p className="text-gray-500">Loading popups...</p>
            ) : popups.length === 0 ? (
              <p className="text-gray-500">No popups found.</p>
            ) : (
              <div className="bg-white overflow-hidden shadow-md rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Popup
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date Range
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Target
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {popups.map((popup) => (
                      <tr key={popup._id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                              <img
                                className="h-10 w-10 rounded-full object-cover"
                                src={`https://api.growwincapital.com/api/popUp/popups/image/${popup._id}`}
                                alt={popup.name}
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {popup.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                Created: {new Date(popup.createdAt).toLocaleDateString()}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            popup.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                          }`}>
                            {popup.isActive ? 'Active' : 'Inactive'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(popup.startDate).toLocaleDateString()} to {new Date(popup.endDate).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {popup.targetUserIds && popup.targetUserIds.length > 0
                            ? `${popup.targetUserIds.length} specific users`
                            : 'All Users'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleTogglePopupStatus(popup._id)}
                              className={`${
                                popup.isActive
                                  ? 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200'
                                  : 'bg-green-100 text-green-800 hover:bg-green-200'
                              } px-3 py-1 rounded-md text-xs`}
                            >
                              {popup.isActive ? 'Deactivate' : 'Activate'}
                            </button>
                            <button
                              onClick={() => handleEditPopup(popup)}
                              className="bg-blue-100 text-blue-800 hover:bg-blue-200 px-3 py-1 rounded-md text-xs"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDeletePopup(popup._id)}
                              className="bg-red-100 text-red-800 hover:bg-red-200 px-3 py-1 rounded-md text-xs"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UnifiedManagementPanel;