// import React, { useState, useEffect } from 'react';

// interface SubTeamMember {
//   _id: string;
//   createdAt: string;
//   level: number;
//   member: string;
//   name: string;
//   number: string;
//   owner: string;
//   updatedAt: string;
//   __v: number;
// }

// const TeamDetail: React.FC = () => {
  
//   const [teamDetails, setteamDetails] = useState<SubTeamMember[]>([]);
//   const [searchTerm, setSearchTerm] = useState(""); // State for the search term
//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };
//   useEffect(()=>{
//        fetchteamDetails()
//   },[])

  
// const fetchteamDetails = async () => {
//   try {
//     // Fetching team details initiated by the user
//     const response = await fetch(`https://api.growwincapital.com/api/subTeam/getSubTeamall`);
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     const data = await response.json();
    
//     if (data.status === "success" && data.subTeam) {
//       // Converting the team object into an array using Object.values()
//       const teamDetailsArray:any = Object.values(data.subTeam);
//       // Setting the state with the team details array
//       setteamDetails(teamDetailsArray);
//     } else {
//       console.error('Unexpected response structure:', data);
//       setteamDetails([]);
//     }

//   } catch (error) {
//     console.error(`Error fetching team details:`, error);
//     // Clear the state in case of an error
//     setteamDetails([]);
//   }
// };

// const filteredDepositData = teamDetails.filter((item:any) =>
//     item.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     item.member.toLowerCase().includes(searchTerm.toLowerCase())
//   );


//   return (
//     <div>
//       <h1 className="text-2xl font-bold mb-4">team Details</h1>
//         <input
//         type="text"
//         placeholder="Search by owner or member email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />
//       <div className="h-[690px] overflow-scroll">
//         <table>
//           <thead>
//             <tr>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Owner</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Member</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">level</th>
//             </tr>
//           </thead>
//           <tbody>
//             {[...filteredDepositData].reverse().map((request: any, index: any) => (
//               <tr key={index + 1}>
//                 <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.owner} </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.member}  </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//   {request.level}
// </td>

//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default TeamDetail;

import React, { useState, useEffect } from "react";

interface SubTeamMember {
  _id: string;
  createdAt: string;
  level: number;
  member: string;
  name: string;
  number: string;
  owner: string;
  updatedAt: string;
}

const TeamDetail: React.FC = () => {
  const [teamDetails, setTeamDetails] = useState<SubTeamMember[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchTeamDetails();
  }, []);

  const fetchTeamDetails = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`https://api.growwincapital.com/api/subTeam/getSubTeamall`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success" && data.subTeam) {
        const teamDetailsArray: SubTeamMember[] = Object.values(data.subTeam);
        setTeamDetails(teamDetailsArray);
      } else {
        console.error("Unexpected response structure:", data);
        setTeamDetails([]);
      }
    } catch (error) {
      console.error("Error fetching team details:", error);
      setTeamDetails([]);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredTeamData = teamDetails.filter(
    (item) =>
      item.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.member.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Team Details</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by owner or member..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-3 border rounded-lg mb-6 shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
      />

      {isLoading ? (
        <p className="text-center text-gray-500">Loading...</p>
      ) : filteredTeamData.length === 0 ? (
        <p className="text-center text-gray-500">No results found.</p>
      ) : (
        <div className="space-y-4">
          {filteredTeamData.map((item, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4 border border-gray-200">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-800">{item.owner}</h2>
                <span className="px-3 py-1 text-sm font-medium bg-gray-100 text-gray-600 rounded-lg">
                  Level: {item.level}
                </span>
              </div>

              <p className="text-gray-600 mt-2">
                <strong>Member:</strong> {item.member}
              </p>
              <p className="text-gray-600">
                <strong>Date:</strong>{" "}
                {new Date(item.createdAt).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamDetail;
