import React, { useState, useEffect, useMemo } from 'react';
import { 
  LineChart, Line, AreaChart, Area, BarChart, Bar, XAxis, YAxis, 
  CartesianGrid, Tooltip, ResponsiveContainer, 
  Cell
} from 'recharts';
import { 
  Users, DollarSign, ArrowUpDown, Award,
  TrendingUp, CircleDollarSign, Wallet, BadgeDollarSign,
  LucideIcon
} from 'lucide-react';

// Interfaces remain the same
interface User {
  _id: string;
  name: string;
  email: string;
  number: string;
  refree: string;
  createdAt: string;
  active: boolean;
}

interface StatCardProps {
  title: string;
  value: number | string;
  icon: LucideIcon;
  trend?: number | null;
  color?: string;
}

interface ChartData {
  name: string;
  deposits: number;
  withdrawals: number;
  transfers: number;
  users: number;
}

interface TransactionData {
  amount: string;
  status: string;
  createdAt: string;
}

interface Transfer {
  amount: string;
  createdAt: string;
}

interface ApiResponse<T> {
  status: string;
  message?: string;
  user?: Record<string, User>;
  Deposit?: Record<string, TransactionData>;
  Withdraw?: Record<string, TransactionData>;
  Transfer?: Record<string, Transfer>;
  referalIncome?: Record<string, { referalIncome: string }>;
  Invest?: { totalAmount: string };
}

interface PreviousValues {
  userCount: number;
  deposits: number;
  withdrawals: number;
  transfers: number;
  referralIncome: number;
  invested: number;
  platinumInvestment: number;
}

// Updated utility functions
const ensureNumber = (value: string | number | undefined): number => {
  if (typeof value === 'number') return value;
  if (!value) return 0;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? 0 : parsed;
};

const formatDate = (dateString: string): string => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) throw new Error('Invalid date');
    return date.toLocaleString('default', { month: 'short' });
  } catch {
    return 'Unknown';
  }
};

const calculateTrend = (currentValue: number, previousValue: number): number => {
  if (previousValue === 0) return 0;
  const trend = ((currentValue - previousValue) / previousValue) * 100;
  return Number(trend.toFixed(1));
};

// StatCard component remains the same
const StatCard = React.memo<StatCardProps & { isLoading: boolean }>(({ 
  title, 
  value, 
  icon: Icon, 
  trend = null, 
  color = "#E8E2F3",
  isLoading 
}) => (
  <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
    {isLoading ? (
      <div className="animate-pulse">
        <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
        <div className="h-8 bg-gray-200 rounded w-3/4"></div>
      </div>
    ) : (
      <div className="flex justify-between items-start">
        <div>
          <p className="text-gray-500 text-sm font-medium mb-2">{title}</p>
          <h3 className="text-2xl font-bold text-gray-800">
            {title.includes('Users') ? value : `$${typeof value === 'number' ? value.toFixed(2) : value}`}
          </h3>
          {trend !== null && (
            <p className={`text-sm mt-2 ${trend >= 0 ? 'text-green-500' : 'text-red-500'}`}>
              {trend >= 0 ? '↑' : '↓'} {Math.abs(trend)}% from last month
            </p>
          )}
        </div>
        <div className="p-3 rounded-lg" style={{ backgroundColor: `${color}20` }}>
          <Icon color={color} size={24} />
        </div>
      </div>
    )}
  </div>
));

StatCard.displayName = 'StatCard';

const AdminDashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState({
    userCount: 0,
    totalDeposits: 0,
    totalWithdrawals: 0,
    totalTransfers: 0,
    totalReferralIncome: 0,
    invested: 0,
    platinumInvestment: 0,
    chartData: [] as ChartData[],
    trends: {
      users: 0,
      deposits: 0,
      withdrawals: 0,
      transfers: 0,
      referralIncome: 0,
      invested: 0,
      platinumInvestment: 0,
      monthlyGrowth: 0
    }
  });

  const [previousValues, setPreviousValues] = useState<PreviousValues>({
    userCount: 0,
    deposits: 0,
    withdrawals: 0,
    transfers: 0,
    referralIncome: 0,
    invested: 0,
    platinumInvestment: 0
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  // Updated API fetch function
  const safelyFetchData = async <T,>(url: string): Promise<ApiResponse<T> | null> => {
    try {
      const response = await fetch(url);
      if (!response.ok) return null;
      const data = await response.json();
      if (data.status === "error" && data.message === "noinvestment") {
        return null;
      }
      return data as ApiResponse<T>;
    } catch (error) {
      console.warn(`Failed to fetch from ${url}:`, error);
      return null;
    }
  };

  // Updated transaction processing functions
  const processTransactions = useMemo(() => (
    transactions: Record<string, TransactionData> | undefined,
    type: 'deposits' | 'withdrawals'
  ): { monthly: Record<string, number>; total: number } => {
    if (!transactions) return { monthly: {}, total: 0 };

    const transactionList = Object.values(transactions);
    
    return transactionList.reduce(
      (acc, item) => {
        if (item.status.toLowerCase() === "success") {
          const month = formatDate(item.createdAt);
          const amount = ensureNumber(item.amount);
          acc.monthly[month] = (acc.monthly[month] || 0) + amount;
          acc.total += amount;
        }
        return acc;
      },
      { monthly: {}, total: 0 } as { monthly: Record<string, number>; total: number }
    );
  }, []);

  const processTransfers = useMemo(() => (
    transfers: Record<string, Transfer> | undefined
  ): { monthly: Record<string, number>; total: number } => {
    if (!transfers) return { monthly: {}, total: 0 };

    return Object.values(transfers).reduce(
      (acc, transfer) => {
        const month = formatDate(transfer.createdAt);
        const amount = ensureNumber(transfer.amount);
        acc.monthly[month] = (acc.monthly[month] || 0) + amount;
        acc.total += amount;
        return acc;
      },
      { monthly: {}, total: 0 } as { monthly: Record<string, number>; total: number }
    );
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setIsLoading(true);

        // Store current values before fetching new data
        setPreviousValues({
          userCount: dashboardData.userCount,
          deposits: dashboardData.totalDeposits,
          withdrawals: dashboardData.totalWithdrawals,
          transfers: dashboardData.totalTransfers,
          referralIncome: dashboardData.totalReferralIncome,
          invested: dashboardData.invested,
          platinumInvestment: dashboardData.platinumInvestment
        });

        // Fetch data
        const [userData, depositData, withdrawalData, transferData, platinumData] = await Promise.all([
          safelyFetchData<User>('https://api.growwincapital.com/api/user/getUsers/all'),
          safelyFetchData<TransactionData>('https://api.growwincapital.com/api/deposit/getDeposit'),
          safelyFetchData<TransactionData>('https://api.growwincapital.com/api/withdraw/getWithdraw'),
          safelyFetchData<Transfer>('https://api.growwincapital.com/api/transfer/getTransferall'),
          safelyFetchData<any>('https://api.growwincapital.com/api/platinum/admin/investors')
        ]);

        // Process user data
        const users = userData?.user ? Object.values(userData.user) : [];
        const userCount = users.length;

        // Process transactions
        const deposits = processTransactions(depositData?.Deposit, 'deposits');
        const withdrawals = processTransactions(withdrawalData?.Withdraw, 'withdrawals');
        const transfers = processTransfers(transferData?.Transfer);

        // Process platinum investments
        const platinumInvestment = Array.isArray(platinumData) 
          ? platinumData
            .filter(investment => !investment?.isClosed)
            .reduce((sum, investment) => sum + ensureNumber(investment?.totalAmount), 0)
          : 0;

        // Process user financials
        const userFinancials = await Promise.all(
          users.map(async (user) => {
            const [referralData, investData] = await Promise.all([
              safelyFetchData(`https://api.growwincapital.com/api/referalIncome/getReferalIncome/${user.email}`),
              safelyFetchData(`https://api.growwincapital.com/api/invest/getInvest/${user.email}`)
            ]);

            return {
              referralIncome: referralData?.referalIncome 
                ? Object.values(referralData.referalIncome)
                  .reduce((sum, income) => sum + ensureNumber(income?.referalIncome), 0)
                : 0,
              investment: investData?.Invest ? ensureNumber(investData.Invest.totalAmount) : 0
            };
          })
        );

        const totalReferralIncome = userFinancials.reduce((sum, item) => sum + item.referralIncome, 0);
        const invested = userFinancials.reduce((sum, item) => sum + item.investment, 0);

        // Generate chart data
        const allMonths = new Set([
          ...Object.keys(deposits.monthly),
          ...Object.keys(withdrawals.monthly),
          ...Object.keys(transfers.monthly)
        ]);

        const chartData = Array.from(allMonths)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
          .map(month => ({
            name: month,
            deposits: deposits.monthly[month] || 0,
            withdrawals: withdrawals.monthly[month] || 0,
            transfers: transfers.monthly[month] || 0,
            users: Math.max(1, userCount / allMonths.size)
          }));

        // Calculate trends
        const trends = {
          users: calculateTrend(userCount, previousValues.userCount),
          deposits: calculateTrend(deposits.total, previousValues.deposits),
          withdrawals: calculateTrend(withdrawals.total, previousValues.withdrawals),
          transfers: calculateTrend(transfers.total, previousValues.transfers),
          referralIncome: calculateTrend(totalReferralIncome, previousValues.referralIncome),
          invested: calculateTrend(invested, previousValues.invested),
          platinumInvestment: calculateTrend(platinumInvestment, previousValues.platinumInvestment),
          monthlyGrowth: calculateTrend(
            deposits.total - withdrawals.total,
            previousValues.deposits - previousValues.withdrawals
          )
        };

        setDashboardData({
          userCount,
          totalDeposits: deposits.total,
          totalWithdrawals: withdrawals.total,
          totalTransfers: transfers.total,
          totalReferralIncome,
          invested,
          platinumInvestment,
          chartData,
          trends
        });

        setIsDataReady(true);
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [processTransactions, processTransfers]);

  // Rest of your render logic
  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading dashboard data...</div>;
  }

  if (!isDataReady) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {Array(8).fill(null).map((_, index) => (
          <StatCard
            key={index}
            title=""
            value={0}
            icon={Users}
            isLoading={true}
          />
        ))}
      </div>
    );
  }

  const {
    userCount,
    totalDeposits,
    totalWithdrawals,
    totalTransfers,
    totalReferralIncome,
    invested,
    platinumInvestment,
    chartData,
    trends
  } = dashboardData;
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard title="Total Users" value={userCount} icon={Users} trend={trends.users} color="#523680" isLoading={isLoading} />
        <StatCard title="Total Deposits" value={totalDeposits} icon={DollarSign} trend={trends.deposits} color="#00C48C" isLoading={isLoading} />
        <StatCard title="Total Withdrawals" value={totalWithdrawals} icon={ArrowUpDown} trend={trends.withdrawals} color="#FF6B6B" isLoading={isLoading} />
        <StatCard title="Total Transfers" value={totalTransfers} icon={Wallet} trend={trends.transfers} color="#4C6FFF" isLoading={isLoading} />
        <StatCard title="Referral Income" value={totalReferralIncome} icon={BadgeDollarSign} trend={trends.referralIncome} color="#FF8F00" isLoading={isLoading} />
        <StatCard title="Total Invested" value={invested} icon={CircleDollarSign} trend={trends.invested} color="#9C27B0" isLoading={isLoading} />
        <StatCard title="Platinum Investment" value={platinumInvestment} icon={Award} trend={trends.platinumInvestment} color="#523680" isLoading={isLoading} />
        <StatCard 
          title="Monthly Growth"
          value={totalDeposits - totalWithdrawals}
          icon={TrendingUp}
          trend={15.8}
          color="#00C48C" isLoading={false}        />
      </div>

     
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        {/* Deposits vs Withdrawals Chart */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Deposits vs Withdrawals</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
              <XAxis 
                dataKey="name" 
                tick={{ fill: '#6B7280' }}
                axisLine={{ stroke: '#E5E7EB' }}
              />
              <YAxis 
                tick={{ fill: '#6B7280' }}
                axisLine={{ stroke: '#E5E7EB' }}
                tickFormatter={(value) => `$${value.toLocaleString()}`}
              />
              <Tooltip
                formatter={(value: number) => [`$${value.toLocaleString()}`, undefined]}
                contentStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  padding: '8px'
                }}
              />
              <Line 
                type="monotone" 
                dataKey="deposits" 
                name="Deposits" 
                stroke="#523680" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 6, fill: '#523680' }}
              />
              <Line 
                type="monotone" 
                dataKey="withdrawals" 
                name="Withdrawals" 
                stroke="#FF6B6B" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 6, fill: '#FF6B6B' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Investment Growth Chart */}
         {/* Investment Growth Chart */}
    <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">Investment Growth</h3>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
          <XAxis 
            dataKey="name" 
            tick={{ fill: '#6B7280' }}
            axisLine={{ stroke: '#E5E7EB' }}
          />
          <YAxis 
            tick={{ fill: '#6B7280' }}
            axisLine={{ stroke: '#E5E7EB' }}
            tickFormatter={(value) => `$${value.toLocaleString()}`}
          />
          <Tooltip
            formatter={(value: number) => [`$${value.toLocaleString()}`, 'Total Investment']}
            contentStyle={{
              backgroundColor: 'white',
              border: '1px solid #E5E7EB',
              borderRadius: '6px',
              padding: '8px'
            }}
          />
          <defs>
            <linearGradient id="investmentGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#523680" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#523680" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Area 
            type="monotone" 
            dataKey="deposits" 
            name="Total Investment"
            stroke="#523680" 
            fill="url(#investmentGradient)" 
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 6, fill: '#523680' }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
        {/* Monthly Transfers Chart */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Monthly Transfers</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
              <XAxis 
                dataKey="name" 
                tick={{ fill: '#6B7280' }}
                axisLine={{ stroke: '#E5E7EB' }}
              />
              <YAxis 
                tick={{ fill: '#6B7280' }}
                axisLine={{ stroke: '#E5E7EB' }}
                tickFormatter={(value) => `$${value.toLocaleString()}`}
              />
              <Tooltip
                formatter={(value: number) => [`$${value.toLocaleString()}`, undefined]}
                contentStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  padding: '8px'
                }}
              />
              <Bar 
                dataKey="transfers" 
                name="Transfers" 
                radius={[4, 4, 0, 0]}
              >
                {chartData.map((_, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={index % 2 === 0 ? '#523680' : '#6B4A99'} 
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* User Growth Chart */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">User Growth</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
              <XAxis 
                dataKey="name" 
                tick={{ fill: '#6B7280' }}
                axisLine={{ stroke: '#E5E7EB' }}
              />
              <YAxis 
                tick={{ fill: '#6B7280' }}
                axisLine={{ stroke: '#E5E7EB' }}
                tickFormatter={(value) => Math.round(value).toLocaleString()}
              />
              <Tooltip
                formatter={(value: number) => [Math.round(value).toLocaleString(), undefined]}
                contentStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  padding: '8px'
                }}
              />
              <defs>
                <linearGradient id="userGrowthGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00C48C" stopOpacity={0.1}/>
                  <stop offset="95%" stopColor="#00C48C" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <Line 
                type="monotone" 
                dataKey="users" 
                name="Active Users"
                stroke="#00C48C" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 6, fill: '#00C48C' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;