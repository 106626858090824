// import { useState, useEffect } from "react";
// import axios from "axios";
// interface SubTeamMember {
//     level: number; // Adjust the type according to your actual data structure
//     // Include other properties as needed
//   }
  
//   interface SubTeamResponse {
//     SubTeam: Record<string, SubTeamMember>;
//   }
//   interface DepositItem {
//     _id: string; // Assuming ObjectId can be treated as string in your context
//   emailOwner: string;
//   emailMember: string;
//   Income: string; // 'Income' is a string here, but you might want to convert it to a number for calculations
//   createdAt: string; // ISODate can be represented as string in TypeScript and converted to Date object when needed
//   updatedAt: string;
//   __v: number;
  
//   }
  
//   interface DepositResponse {
//     teamIncome: Record<string, DepositItem>;
//   }

// const TeamIncome = () => {
//   const [depositData, setDepositData] = useState<any>([]);
//     const [isLoading, setIsLoading] = useState(true); // State to track loading status
//   const [searchTerm, setSearchTerm] = useState(''); // State for the search term




// useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };
//     setIsLoading(true);
//     axios
//       .get<DepositResponse>(`https://api.growwincapital.com/api/teamincome/getIncomeTeamAll`, { headers })
//       .then(async (response) => {
//         const deposit = Object.values(response.data.teamIncome);

//         // Fetch levels for each deposit item
//         const levelPromises = deposit.map((item) =>
//           axios.get<SubTeamResponse>(`https://api.growwincapital.com/api/subTeam/getsubTeam/${item.emailOwner}/${item.emailMember}`, { headers })
//             .then(response => Object.values(response.data.SubTeam)[0]?.level || null) // Extract level or null if not found
//             .catch(() => null) // Return null in case of an error
//         );

//         // Wait for all level fetch promises to resolve
//         const levels = await Promise.all(levelPromises);

//         // Merge the deposit data with the fetched levels
//         const depositWithLevels = deposit.map((item, index) => ({
//           ...item,
//           level: levels[index], // Add level to each deposit item
//         }));

//         setDepositData(depositWithLevels);
//           setIsLoading(false)
//       })
//       .catch((error) => {
//         console.log("Error fetching team income data: " + error);
//           setIsLoading(false)
//       });
//   }, []);

//     // Event handler for search input changes
//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filtered data based on the search term
//   const filteredDepositData = depositData.filter((item: DepositItem) =>
//     item.emailOwner.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     item.emailMember.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div>
//       <h1 className="text-2xl font-bold mb-4">Team Income</h1>
//         <input
//         type="text"
//         placeholder="Search by email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />
//       <div className="h-[690px] overflow-scroll">
//           {isLoading ? (
//           <div>Loading...</div> 
//         ) : (
//         <table>
//           <thead>
//             <tr>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">To</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">From</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Level</th>
//             </tr>
//           </thead>
//           <tbody>
//             {[...depositData].reverse().map((request: any, index: any) => (
//               <tr key={index + 1}>
//                 <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.emailOwner} </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.emailMember}  </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//                    {Number(request.Income).toFixed(2)}
//                 </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.level}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//                 )}

//       </div>
//     </div>
//   );
// };

// export default TeamIncome;
import { useState, useEffect } from "react";
import axios from "axios";

interface TeamIncomeItem {
  _id: string;
  emailOwner: string;
  emailMember: string;
  Income: string;
  createdAt: string;
  level?: number | "N/A";
}

const TeamIncome = () => {
  const [teamIncome, setTeamIncome] = useState<TeamIncomeItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTeamIncome = async () => {
      try {
        setIsLoading(true);
        const headers = {
          "Content-Type": "application/json",
        };

        const response = await axios.get<{ teamIncome: Record<string, TeamIncomeItem> }>(
          "https://api.growwincapital.com/api/teamincome/getIncomeTeamAll",
          { headers }
        );

        if (response.data.teamIncome) {
          let incomeData: TeamIncomeItem[] = Object.values(response.data.teamIncome);

          // Fetch levels for each entry
          const levelPromises = incomeData.map((item) =>
            axios
              .get<{ SubTeam: Record<string, { level?: number }> }>(
                `https://api.growwincapital.com/api/subTeam/getsubTeam/${item.emailOwner}/${item.emailMember}`,
                { headers }
              )
              .then((res) => {
                const level = Object.values(res.data.SubTeam)[0]?.level;
                return typeof level === "number" ? level : "N/A";
              })
              .catch(() => "N/A")
          );

          const levels = await Promise.all(levelPromises);

          // Merge levels with data
          const finalData = incomeData.map((item, index) => ({
            ...item,
            level: levels[index] as number | "N/A",
          }));

          setTeamIncome(finalData);
        }
      } catch (error) {
        console.error("Error fetching team income:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamIncome();
  }, []);

  const filteredData = teamIncome.filter(
    (item) =>
      item.emailOwner.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.emailMember.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Team Income</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-3 border rounded-lg mb-6 shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
      />

      {isLoading ? (
        <p className="text-center text-gray-500">Loading...</p>
      ) : filteredData.length === 0 ? (
        <p className="text-center text-gray-500">No results found.</p>
      ) : (
        <div className="space-y-4">
          {filteredData.map((item, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4 border border-gray-200">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-800">{item.emailOwner}</h2>
                <span className="px-3 py-1 text-sm font-medium bg-gray-100 text-gray-600 rounded-lg">
                  Level: {item.level}
                </span>
              </div>

              <p className="text-gray-600 mt-2">
                <strong>From:</strong> {item.emailMember}
              </p>
              <p className="text-gray-600">
                <strong>Date:</strong>{" "}
                {new Date(item.createdAt).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </p>
              <p className="text-green-600 font-bold text-lg mt-2">
                <strong>Income:</strong> ${Number(item.Income || 0).toFixed(2)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamIncome;
