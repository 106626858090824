
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';

type PathTitleMapping = {
  [key: string]: string;
};

const Layout: React.FC = () => {
  const location = useLocation();
  const [currentTime, setCurrentTime] = useState<string>('');
  const [userName, setUserName] = useState<string>("");
  const [hasAccess, setHasAccess] = useState<boolean>(true);

  // Update time every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(
        now.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        })
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Check user permissions
  useEffect(() => {
    // Get user name
    const name = localStorage.getItem("name");
    setUserName(name || "Admin User");

    // Check permission for current path
    const currentPath = location.pathname;
    const permissionsStr = localStorage.getItem("permissions");
    const isSuperAdmin = localStorage.getItem("isSuperAdmin") === "true";
    
    if (isSuperAdmin) {
      setHasAccess(true);
      return;
    }
    
    if (permissionsStr) {
      try {
        const permissions = JSON.parse(permissionsStr);
        setHasAccess(permissions.includes(currentPath));
      } catch (error) {
        console.error("Error parsing permissions:", error);
        setHasAccess(false);
      }
    } else {
      setHasAccess(false);
    }
  }, [location.pathname]);

  const pathTitles: PathTitleMapping = {
    '/': 'Dashboard',
    '/users': 'Users Management',
    '/teamDetails': 'Team Details',
    '/teamincome': 'Team Income Analytics',
    '/income': 'Personal Income',
    '/referal': 'Referral Program',
    '/transferDetails': 'Transfer History',
    '/wallet': 'Digital Wallet',
    '/invest': 'Investment Portal',
    '/investHistory': 'Investment History',
    '/deposit': 'Deposit Management',
    '/withdraw': 'Withdrawal Requests',
    '/withdrawData': 'Withdrawal Analytics',
    '/banner': 'Banner Management',
    '/kyc': 'KYC Verification',
    '/ticket': 'Support Tickets',
    '/platinumData': 'Platinum Members',
    '/platinumIncome': 'Platinum Income',
    '/platinumIncomeTeam': 'Platinum Team Income',
    '/notification': 'Notification Management',
    '/appVersion': 'App Version Management',
    '/roles': 'Roles & Responsibilities',
    '/blogs':'Blogs'
  };

  const getTopBarTitle = (pathname: string): string => {
    return pathTitles[pathname] || 'Growwincapital Dashboard';
  };

  // If no access, redirect to dashboard or access denied page
  if (!hasAccess && location.pathname !== '/') {
    return <Navigate to="/access-denied" replace />;
  }

  return (
    <div className='flex h-screen bg-gray-50 overflow-hidden'>
      <Sidebar />
      <div className='flex-1 overflow-auto relative'>
        {/* Modern App Bar */}
        <div className="bg-white shadow-sm border-b border-gray-100 sticky top-0 z-10">
          <div className="max-w-full mx-auto px-6 py-3">
            <div className="flex items-center justify-between">
              {/* Left side - Title */}
              <div>
                <h1 className="text-xl font-semibold text-[#523680]">
                  {getTopBarTitle(location.pathname)}
                </h1>
              </div>

              {/* Right side - User Info & DateTime */}
              <div className="flex items-center gap-6">
                {/* User Display */}
                <div className="flex items-center gap-2">
                  <div className="w-8 h-8 rounded-full bg-[#523680] flex items-center justify-center text-white text-sm font-semibold">
                    {userName.charAt(0).toUpperCase()}
                  </div>
                  <span className="text-sm font-medium text-gray-700">{userName}</span>
                </div>
                
                {/* DateTime */}
                <div className="flex flex-col items-end space-y-1">
                  <div className="text-base font-medium text-[#523680]">
                    {new Date().toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </div>
                  <div className="text-sm text-[#523680]/80 font-mono tracking-wide">
                    {currentTime}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className='p-6'>
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-6">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;