import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Base URL for API requests
const API_BASE_URL = 'https://api.growwincapital.com/api/blogs';

// Types
interface Blog {
  _id: string;
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  tags: string[];
  status: 'draft' | 'published';
  featuredImage?: string;
  author: string;
  rating?: number;
  commentCount?: number;
  createdAt: string;
  publishedAt?: string;
}

interface BlogFormData {
  title: string;
  content: string;
  excerpt: string;
  tags: string;
  status: 'draft' | 'published';
  featuredImage: string;
  author: string;
}

interface PaginationData {
  currentPage: number;
  totalPages: number;
  hasNext: boolean;
  hasPrev: boolean;
}

interface BlogsResponse {
  success: boolean;
  count: number;
  total: number;
  pagination: PaginationData;
  data: Blog[];
}

interface BlogResponse {
  success: boolean;
  data: Blog;
}

interface ImageUploadResponse {
  success: boolean;
  data: {
    filename: string;
    url: string;
  };
}

// Props types
interface BlogListProps {
  blogs: Blog[];
  onEdit: (blog: Blog) => void;
  onDelete: (id: string) => void;
  onRefresh: () => void;
}

interface BlogFormProps {
  blog: Blog | null;
  onSubmit: (blogData: Omit<Blog, '_id' | 'createdAt' | 'slug'>) => Promise<void>;
  onCancel: () => void;
}

// Blog List Component
const BlogList: React.FC<BlogListProps> = ({ blogs, onEdit, onDelete, onRefresh }) => {
  return (
    <div className="overflow-x-auto shadow-md rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Author</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comments</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {blogs.map((blog) => (
            <tr key={blog._id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">{blog.title}</div>
                <div className="text-sm text-gray-500">{blog.slug}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  blog.status === 'published' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {blog.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {blog.author}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {blog.rating ? blog.rating.toFixed(1) : 'N/A'} / 5
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {blog.commentCount || 0}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {new Date(blog.createdAt).toLocaleDateString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button 
                  onClick={() => onEdit(blog)} 
                  className="text-indigo-600 hover:text-indigo-900 mr-4"
                >
                  Edit
                </button>
                <button 
                  onClick={() => onDelete(blog._id)} 
                  className="text-red-600 hover:text-red-900"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Blog Form Component
const BlogForm: React.FC<BlogFormProps> = ({ blog, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState<BlogFormData>({
    title: '',
    content: '',
    excerpt: '',
    tags: '',
    status: 'draft',
    featuredImage: '',
    author: ''
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [slugPreview, setSlugPreview] = useState<string>('');

  useEffect(() => {
    if (blog) {
      setFormData({
        title: blog.title || '',
        content: blog.content || '',
        excerpt: blog.excerpt || '',
        tags: blog.tags ? blog.tags.join(', ') : '',
        status: blog.status || 'draft',
        featuredImage: blog.featuredImage || '',
        author: blog.author || ''
      });
    }
  }, [blog]);

  // Generate slug preview whenever title changes
  useEffect(() => {
    const generatedSlug = formData.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    setSlugPreview(generatedSlug);
  }, [formData.title]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let imageUrl = formData.featuredImage;

      // Upload image if a new one is selected
      if (imageFile) {
        const imageData = new FormData();
        imageData.append('image', imageFile);
        
        const uploadResponse = await axios.post<ImageUploadResponse>(
          `${API_BASE_URL}/blog-image`,
          imageData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        
        if (uploadResponse.data.success) {
          imageUrl = uploadResponse.data.data.url;
        }
      }

      // Prepare blog data for submission
      const blogData = {
        title: formData.title,
        content: formData.content,
        excerpt: formData.excerpt,
        tags: formData.tags.split(',').map(tag => tag.trim()).filter(tag => tag),
        status: formData.status as 'draft' | 'published',
        featuredImage: imageUrl,
        author: formData.author || 'Admin', // Provide a default if empty
        slug: slugPreview // Add generated slug
      };

      await onSubmit(blogData);
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to save blog post');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">{blog ? 'Edit Blog Post' : 'Create New Blog Post'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        {slugPreview && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Slug Preview
            </label>
            <div className="bg-gray-100 p-2 rounded text-gray-700">{slugPreview}</div>
          </div>
        )}

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="author">
            Author
          </label>
          <input
            type="text"
            id="author"
            name="author"
            value={formData.author}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="excerpt">
            Excerpt
          </label>
          <textarea
            id="excerpt"
            name="excerpt"
            value={formData.excerpt}
            onChange={handleChange}
            rows={2}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
            Content
          </label>
          <textarea
            id="content"
            name="content"
            value={formData.content}
            onChange={handleChange}
            rows={10}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tags">
            Tags (comma separated)
          </label>
          <input
            type="text"
            id="tags"
            name="tags"
            value={formData.tags}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="featuredImage">
            Featured Image
          </label>
          <input
            type="file"
            id="featuredImage"
            name="featuredImage"
            onChange={handleImageChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            accept="image/jpeg,image/png,image/webp,image/jpg"
          />
          {formData.featuredImage && (
            <div className="mt-2">
              <p className="text-sm text-gray-500">Current image:</p>
              <img src={formData.featuredImage} alt="Featured" className="h-24 mt-1 object-cover" />
            </div>
          )}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
            Status
          </label>
          <select
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
          </select>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={onCancel}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`bg-[#523680] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? 'Saving...' : (blog ? 'Update' : 'Create')}
          </button>
        </div>
      </form>
    </div>
  );
};

// Main Blogs Component
const Blogs: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentBlog, setCurrentBlog] = useState<Blog | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [tagFilter, setTagFilter] = useState<string>('');

  // Fetch blogs
  const fetchBlogs = async (): Promise<void> => {
    setLoading(true);
    try {
      let url = `${API_BASE_URL}?page=${page}&limit=10`;
      
      if (searchTerm) url += `&search=${searchTerm}`;
      if (statusFilter) url += `&status=${statusFilter}`;
      if (tagFilter) url += `&tag=${tagFilter}`;
      
      const response = await axios.get<BlogsResponse>(url);
      
      if (response.data.success) {
        setBlogs(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
      } else {
        setError('Failed to fetch blogs');
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setError('Failed to fetch blogs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [page, searchTerm, statusFilter, tagFilter]);

  // Create blog
  const createBlog = async (blogData: Omit<Blog, '_id' | 'createdAt' | 'slug'>): Promise<void> => {
    try {
      const response = await axios.post<BlogResponse>(API_BASE_URL, blogData);
      
      if (response.data.success) {
        toast.success('Blog created successfully');
        setShowForm(false);
        fetchBlogs();
      }
    } catch (error: any) {
      console.error('Error creating blog:', error);
      if (error.response?.data?.error) {
        // Handle array of error messages
        if (Array.isArray(error.response.data.error)) {
          toast.error(error.response.data.error.join(', '));
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error('Failed to create blog');
      }
      throw error;
    }
  };

  // Update blog
  const updateBlog = async (blogData: Omit<Blog, '_id' | 'createdAt' | 'slug'>): Promise<void> => {
    try {
      if (!currentBlog) throw new Error("No blog selected for update");
      
      const response = await axios.put<BlogResponse>(`${API_BASE_URL}/${currentBlog._id}`, blogData);
      
      if (response.data.success) {
        toast.success('Blog updated successfully');
        setShowForm(false);
        setCurrentBlog(null);
        fetchBlogs();
      }
    } catch (error: any) {
      console.error('Error updating blog:', error);
      if (error.response?.data?.error) {
        // Handle array of error messages
        if (Array.isArray(error.response.data.error)) {
          toast.error(error.response.data.error.join(', '));
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error('Failed to update blog');
      }
      throw error;
    }
  };

  // Delete blog
  const deleteBlog = async (id: string): Promise<void> => {
    if (!window.confirm('Are you sure you want to delete this blog post?')) {
      return;
    }
    
    try {
      const response = await axios.delete<{success: boolean}>(`${API_BASE_URL}/${id}`);
      
      if (response.data.success) {
        toast.success('Blog deleted successfully');
        fetchBlogs();
      }
    } catch (error) {
      console.error('Error deleting blog:', error);
      toast.error('Failed to delete blog');
    }
  };

  // Handle blog submission (create or update)
  const handleSubmit = async (blogData: Omit<Blog, '_id' | 'createdAt' | 'slug'>): Promise<void> => {
    if (currentBlog) {
      await updateBlog(blogData);
    } else {
      await createBlog(blogData);
    }
  };

  // Handle edit action
  const handleEdit = (blog: Blog): void => {
    setCurrentBlog(blog);
    setShowForm(true);
  };

  // Handle create new blog action
  const handleCreateNew = (): void => {
    setCurrentBlog(null);
    setShowForm(true);
  };

  // Handle form cancel
  const handleCancel = (): void => {
    setShowForm(false);
    setCurrentBlog(null);
  };

  // Handle page change
  const handlePageChange = (newPage: number): void => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // Handle search
  const handleSearch = (e: FormEvent): void => {
    e.preventDefault();
    setPage(1); // Reset to first page on new search
    fetchBlogs();
  };

  // Handle filter changes
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const { name, value } = e.target;
    if (name === 'status') setStatusFilter(value);
    if (name === 'tag') setTagFilter(value);
    setPage(1); // Reset to first page on filter change
  };

  // Clear filters
  const clearFilters = (): void => {
    setSearchTerm('');
    setStatusFilter('');
    setTagFilter('');
    setPage(1);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Blog Management</h1>
        {!showForm && (
          <button
            onClick={handleCreateNew}
            className="bg-[#523680] hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Create New Blog
          </button>
        )}
      </div>
      
      {/* Form or List */}
      {showForm ? (
        <BlogForm 
          blog={currentBlog} 
          onSubmit={handleSubmit} 
          onCancel={handleCancel} 
        />
      ) : (
        <div>
          {/* Filters */}
          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <form onSubmit={handleSearch} className="flex flex-wrap items-end gap-4">
              <div className="flex-grow min-w-[200px]">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="search">
                  Search
                </label>
                <input
                  type="text"
                  id="search"
                  value={searchTerm}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                  placeholder="Search blogs..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              
              <div className="w-40">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={statusFilter}
                  onChange={handleFilterChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">All</option>
                  <option value="draft">Draft</option>
                  <option value="published">Published</option>
                </select>
              </div>
              
              <div className="w-40">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tag">
                  Tag
                </label>
                <input
                  type="text"
                  id="tag"
                  name="tag"
                  value={tagFilter}
                  onChange={handleFilterChange}
                  placeholder="Filter by tag"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              
              <div className="flex gap-2">
                <button
                  type="submit"
                  className="bg-[#523680] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Search
                </button>
                <button
                  type="button"
                  onClick={clearFilters}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Clear
                </button>
              </div>
            </form>
          </div>
          
          {/* Blog List */}
          {loading ? (
            <div className="flex justify-center my-8">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
            </div>
          ) : error ? (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Error! </strong>
              <span className="block sm:inline">{error}</span>
            </div>
          ) : blogs.length === 0 ? (
            <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative" role="alert">
              <p>No blogs found. Create your first blog post!</p>
            </div>
          ) : (
            <>
              <BlogList 
                blogs={blogs} 
                onEdit={handleEdit} 
                onDelete={deleteBlog} 
                onRefresh={fetchBlogs} 
              />
              
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-6">
                  <nav className="inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                      className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
                        page === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      Previous
                    </button>
                    
                    {[...Array(totalPages)].map((_, i) => (
                      <button
                        key={i + 1}
                        onClick={() => handlePageChange(i + 1)}
                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                          page === i + 1
                            ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                            : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                        }`}
                      >
                        {i + 1}
                      </button>
                    ))}
                    
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page === totalPages}
                      className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
                        page === totalPages ? 'text-gray-400 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      Next
                    </button>
                  </nav>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Blogs;