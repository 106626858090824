import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { format } from "date-fns";
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Divider,
  Badge,
  Chip,
  CircularProgress,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Collapse
} from "@mui/material";
import {
  Search as SearchIcon,
  Send as SendIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  AccessTime as TimeIcon,
  Email as EmailIcon,
  Person as PersonIcon
} from "@mui/icons-material";
interface Ticket {
  _id: string;
  userName: string;
  userEmail: string;
  subject: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface Message {
  _id: string;
  sender: string;
  message: string;
  timestamp: string;
  attachments?: Array<{
    _id: string;
    filename: string;
  }>;
}

const Ticket: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [filteredTickets, setFilteredTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [imageURLs, setImageURLs] = useState<{ [key: string]: string }>({});
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Fetch tickets and set up filtering
  useEffect(() => {
    fetchTickets();
  }, []);

  useEffect(() => {
    filterTickets();
  }, [tickets, searchQuery, statusFilter]);

  const fetchTickets = async () => {
    try {
      const response = await axios.get("https://api.growwincapital.com/api/adminTicket/tickets/all");
      // Sort tickets by creation date (newest first)
      const sortedTickets = response.data.tickets.sort((a: Ticket, b: Ticket) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setTickets(sortedTickets);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  const filterTickets = () => {
    let filtered = [...tickets];
    
    // Apply search filter
    if (searchQuery) {
      filtered = filtered.filter(ticket => 
        ticket.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ticket.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ticket.userEmail.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    
    // Apply status filter
    if (statusFilter !== "all") {
      filtered = filtered.filter(ticket => ticket.status.toLowerCase() === statusFilter);
    }
    
    setFilteredTickets(filtered);
  };

  const fetchConversation = async (ticketId: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.growwincapital.com/api/adminTicket/tickets/${ticketId}/conversation`
      );
      setConversation(response.data.conversation.messages);
      await loadImages(response.data.conversation.messages);
    } catch (error) {
      console.error("Error fetching conversation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTicketSelect = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    fetchConversation(ticket._id);
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedTicket) return;
    
    try {
      await axios.post(
        `https://api.growwincapital.com/api/adminTicket/tickets/${selectedTicket._id}/reply`,
        { message: newMessage }
      );
      setNewMessage("");
      fetchConversation(selectedTicket._id);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleCloseTicket = async () => {
    if (!selectedTicket) return;
    try {
      await axios.post(`https://api.growwincapital.com/api/adminTicket/tickets/${selectedTicket._id}/close`);
      await fetchTickets();
      setSelectedTicket(prev => prev ? {...prev, status: "closed"} : null);
    } catch (error) {
      console.error("Error closing ticket:", error);
    }
  };

  const fetchImage = async (fileName: string): Promise<string | undefined> => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `https://api.growwincapital.com/api/adminTicket/images/${fileName}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };

  const loadImages = async (messages: Message[]) => {
    const imagePromises = messages.flatMap(msg =>
      msg.attachments ? msg.attachments.map(async attachment => {
        const imageUrl = await fetchImage(attachment.filename);
        if (imageUrl) {
          setImageURLs(prev => ({ ...prev, [attachment.filename]: imageUrl }));
        }
      }) : []
    );
    await Promise.all(imagePromises);
  };


  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "open": return "success";
      case "closed": return "error";
      default: return "warning";
    }
  };

 
  return (
    <Container maxWidth="lg" className="py-4">
      {/* Search and Filter Section */}
      <Paper elevation={3} className="mb-6 p-4 sticky top-0 z-10 bg-white">
        <Box className="flex gap-4 flex-wrap">
          <TextField
            className="flex-1"
            variant="outlined"
            size="small"
            placeholder="Search tickets..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="text-gray-400" />
                </InputAdornment>
              ),
            }}
          />

          <FormControl size="small" style={{ minWidth: 150 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              label="Status"
            >
              <MenuItem value="all">All Tickets</MenuItem>
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Paper>

      {/* Tickets List */}
      <Box className="space-y-4">
        {filteredTickets.map((ticket) => (
          <Paper
            key={ticket._id}
            elevation={2}
            className="overflow-hidden transition-all duration-200 hover:shadow-md"
          >
            {/* Ticket Header */}
            <Box 
              className="p-4 cursor-pointer hover:bg-gray-50"
              onClick={() => handleTicketSelect(ticket)}
            >
              <Box className="flex justify-between items-start mb-3">
                <Box className="flex-1">
                  <Typography variant="h6" className="font-medium text-gray-800">
                    {ticket.subject}
                  </Typography>
                  <Box className="flex items-center gap-3 mt-2">
                    <Box className="flex items-center gap-1 text-gray-600">
                      <PersonIcon fontSize="small" />
                      <Typography variant="body2">{ticket.userName}</Typography>
                    </Box>
                    <Box className="flex items-center gap-1 text-gray-600">
                      <EmailIcon fontSize="small" />
                      <Typography variant="body2">{ticket.userEmail}</Typography>
                    </Box>
                    <Box className="flex items-center gap-1 text-gray-600">
                      <TimeIcon fontSize="small" />
                      <Typography variant="body2">
                        {format(new Date(ticket.createdAt), 'MMM dd, yyyy HH:mm')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex items-center gap-2">
                  <Chip
                    label={ticket.status}
                    color={getStatusColor(ticket.status)}
                    size="small"
                    className="min-w-[80px]"
                  />
                  {selectedTicket?._id === ticket._id ? (
                    <ExpandLessIcon className="text-gray-500" />
                  ) : (
                    <ExpandMoreIcon className="text-gray-500" />
                  )}
                </Box>
              </Box>
            </Box>

            {/* Conversation Section */}
            <Collapse in={selectedTicket?._id === ticket._id}>
              <Divider />
              <Box className="bg-gray-50">
                {/* Messages */}
                <Box className="max-h-[400px] overflow-y-auto p-4">
                  {loading ? (
                    <Box className="flex justify-center items-center p-4">
                      <CircularProgress size={24} />
                    </Box>
                  ) : (
                    conversation.map((msg) => (
                      <Box
                        key={msg._id}
                        className={`flex ${msg.sender === "admin" ? "justify-end" : "justify-start"} mb-4`}
                      >
                        <Paper
                          elevation={1}
                          className={`max-w-[80%] p-3 ${
                            msg.sender === "admin" 
                              ? "bg-blue-100 rounded-l-xl rounded-tr-xl" 
                              : "bg-white rounded-r-xl rounded-tl-xl"
                          }`}
                        >
                          <Typography 
                            variant="subtitle2" 
                            className={msg.sender === "admin" ? "text-blue-700" : "text-green-700"}
                          >
                            {msg.sender === "admin" ? "Admin" : "User"}
                          </Typography>
                          
                          <Typography variant="body1" className="my-1 text-gray-800">
                            {msg.message}
                          </Typography>

                          {msg.attachments && msg.attachments.length > 0 && (
                            <Box className="mt-2 flex flex-wrap gap-2">
                              {msg.attachments.map((attachment) => (
                                <img
                                  key={attachment._id}
                                  src={imageURLs[attachment.filename] || ""}
                                  alt="attachment"
                                  className="w-32 h-32 object-cover rounded-lg cursor-pointer hover:opacity-90 transition-opacity"
                                  onClick={() => window.open(imageURLs[attachment.filename], "_blank")}
                                />
                              ))}
                            </Box>
                          )}

                          <Typography variant="caption" className="text-gray-500 block mt-1">
                            {format(new Date(msg.timestamp), 'MMM dd, HH:mm')}
                          </Typography>
                        </Paper>
                      </Box>
                    ))
                  )}
                  <div ref={messagesEndRef} />
                </Box>

                {/* Message Input */}
                {ticket.status !== "closed" && (
                  <Box className="p-4 bg-white border-t">
                    <Box className="flex gap-2">
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                      />
                      <IconButton
                        color="primary"
                        onClick={handleSendMessage}
                        disabled={!newMessage.trim()}
                        className="bg-blue-50 hover:bg-blue-100"
                      >
                        <SendIcon />
                      </IconButton>
                      {ticket.status !== "closed" && (
                        <IconButton
                          color="error"
                          onClick={handleCloseTicket}
                          title="Close Ticket"
                          className="bg-red-50 hover:bg-red-100"
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Collapse>
          </Paper>
        ))}
      </Box>
    </Container>
  );
};

export default Ticket;