import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ShieldAlert, Home } from 'lucide-react';

const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      <div className="bg-white rounded-lg shadow-md p-8 max-w-md w-full text-center">
        <div className="flex justify-center mb-6">
          <ShieldAlert size={64} className="text-red-500" />
        </div>
        
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Access Denied</h1>
        
        <p className="text-gray-600 mb-6">
          You don't have permission to access this page. Please contact the system administrator if you believe this is an error.
        </p>
        
        <button
          onClick={() => navigate('/')}
          className="flex items-center justify-center gap-2 mx-auto py-2.5 px-4 rounded-lg text-white bg-[#523680] hover:bg-[#3f2a60] transition-colors duration-200"
        >
          <Home size={18} />
          <span>Return to Dashboard</span>
        </button>
      </div>
    </div>
  );
};

export default AccessDenied;