// import { useState, useEffect } from "react";
// import axios from "axios";

// const Referal = () => {
//   const [depositData, setDepositData] = useState<any>([]);
//   const [status, setStatus] = useState(false);
//   const [searchTerm, setSearchTerm] = useState(""); // State for the search term

//   useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     axios
//       .get(`https://api.growwincapital.com/api/referalIncome/getReferalIncomeAll`, { headers })
//       .then((response) => {
//         console.log(response.data.Referal);
//         const deposit: any = Object.values(response.data.Referal);
//         setDepositData(deposit);
//       })
//       .catch((error) => {
//         console.log("no error " + error);
//       });
//   }, [status]);

//     // Event handler for search input changes
//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filtered data based on the search term
//   const filteredDepositData = depositData.filter((item:any) =>
//     item.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     item.member.toLowerCase().includes(searchTerm.toLowerCase())
//   );


//   return (
//     <div>
//       <h1 className="text-2xl font-bold mb-4">Referal Income</h1>
//         <input
//         type="text"
//         placeholder="Search by owner or member email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />
//       <div className="h-[690px] overflow-scroll">
//         <table>
//           <thead>
//             <tr>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">To</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">From</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Level</th>
//             </tr>
//           </thead>
//           <tbody>
//             {[...filteredDepositData].reverse().map((request: any, index: any) => (
//               <tr key={index + 1}>
//                 <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.owner} </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.member}  </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//   {Number(request.referalIncome).toFixed(2)}
// </td>

//                 <td className="py-3 px-8 border-b border-gray-200">{request.level}</td>
             
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Referal;
import { useState, useEffect } from "react";
import axios from "axios";

interface ReferralIncome {
  _id: string;
  owner: string;
  member: string;
  referalIncome: string;
  level: string;
  createdAt: string;
}

const Referral: React.FC = () => {
  const [referralData, setReferralData] = useState<ReferralIncome[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchReferralData();
  }, []);

  const fetchReferralData = async () => {
    try {
      const response = await axios.get("https://api.growwincapital.com/api/referalIncome/getReferalIncomeAll");
  
      if (response.data.Referal) {
        const sortedData: ReferralIncome[] = Object.values(response.data.Referal) as ReferralIncome[];
  
        sortedData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  
        setReferralData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching referral data:", error);
    }
  };
  
  const filteredReferralData = referralData.filter(
    (item) =>
      item.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.member.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Referral Income</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by Owner or Member..."
        className="border rounded-lg p-3 w-full mb-6 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Referral List */}
      <div className="space-y-6">
        {filteredReferralData.map((referral, index) => (
          <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-semibold text-gray-800">{referral.owner}</h2>
              <span className="px-3 py-1 rounded-full text-xs font-semibold bg-green-100 text-green-700">
                Level {referral.level}
              </span>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-3">
              <p className="text-gray-600">
                <strong>From:</strong> {referral.member}
              </p>
              <p className="text-gray-600">
                <strong>Amount:</strong> ${Number(referral.referalIncome).toFixed(2)}
              </p>
              <p className="text-gray-600">
                <strong>Income Date:</strong> {new Date(referral.createdAt).toLocaleDateString("en-GB")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Referral;
