import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { LockIcon, MailIcon } from "lucide-react";

interface FormData {
  email: string;
  password: string;
}

interface FormErrors {
  email?: string;
  password?: string;
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const LoginPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ email: "", password: "" });
  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({ 
    severity: "success", 
    message: "", 
    open: false 
  });
  const navigate = useNavigate();
  const location = useLocation();
  
  // Redirect to dashboard if already logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    // Clear error when user starts typing
    if (errors[name as keyof FormErrors]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  const validateForm = (): boolean => {
    let valid = true;
    let newErrors: FormErrors = {};

    // Email validation
    if (!formData.email) {
      valid = false;
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      valid = false;
      newErrors.email = "Email is invalid";
    }

    // Password validation
    if (!formData.password) {
      valid = false;
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);
    return valid;
  };

  const handleCloseSnackbar = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const loginClick = async () => {
    if (!validateForm()) {
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.post('https://api.growwincapital.com/api/auth/login', formData);
  
      if (response.data.status === 'fail') {
        setSnackbar({ 
          severity: "error", 
          message: "Invalid email or password", 
          open: true 
        });
      } else {
        // Clear any existing data first
        localStorage.clear();
        
        // Store token
        localStorage.setItem('token', response.data.access_token);
        
        // Store user info
        localStorage.setItem('email', formData.email);
        localStorage.setItem('name', response.data.name || formData.email.split('@')[0]);
        localStorage.setItem('role', response.data.role || 'user');
        
        // Handle permissions
        if (response.data.role === 'admin') {
          if (!response.data.permissions || response.data.permissions.length === 0) {
            // Super admin - set all permissions
            const allPermissions = getFullPermissionsList();
            localStorage.setItem('permissions', JSON.stringify(allPermissions));
            localStorage.setItem('isSuperAdmin', 'true');
          } else {
            // Regular admin with specific permissions
            localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
            localStorage.setItem('isSuperAdmin', 'false');
          }
          
          // Navigate to dashboard
          navigate('/');
        } else {
          // Handle non-admin users
          setSnackbar({ 
            severity: "error", 
            message: "You don't have admin access", 
            open: true 
          });
        }
      }
    } catch (error: any) {
      const message = error.response?.data?.message || "Something went wrong. Please try again.";
      setSnackbar({ severity: "error", message, open: true });
    } finally {
      setLoading(false);
    }
  };

  // Function to get all available permissions
  const getFullPermissionsList = () => {
    return [
      '/', '/users', '/teamDetails', '/teamincome', '/income', '/referal',
      '/transferDetails', '/wallet', '/invest', '/investHistory', '/deposit',
      '/withdraw', '/withdrawData', '/banner', '/kyc', '/ticket', '/platinumData',
      '/platinumIncome', '/platinumIncomeTeam', '/notification', '/appVersion', '/roles','/blogs'
    ];
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginClick();
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Left side - Image */}
      <div className="hidden lg:flex lg:w-1/2 bg-[#523680] items-center justify-center">
        <div className="max-w-md px-8">
          <h1 className="text-5xl font-bold text-white mb-6">Welcome Back</h1>
          <p className="text-gray-200 text-xl">
            Log in to access your Growwin Capital admin dashboard and manage your investment platform.
          </p>
        </div>
      </div>

      {/* Right side - Login Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md">
          <div className="text-center mb-10">
            <h2 className="text-3xl font-bold text-[#523680] mb-2">
              Growwin<span className="text-[#b9aecc]">capital</span>
            </h2>
            <p className="text-gray-600">Admin Management Portal</p>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-6">Sign In</h3>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Email Input */}
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MailIcon className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`w-full pl-10 pr-3 py-2 border ${
                      errors.email ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#523680]`}
                    placeholder="your@email.com"
                  />
                </div>
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                )}
              </div>

              {/* Password Input */}
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <LockIcon className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className={`w-full pl-10 pr-3 py-2 border ${
                      errors.password ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#523680]`}
                    placeholder="••••••••"
                  />
                </div>
                {errors.password && (
                  <p className="mt-1 text-sm text-red-600">{errors.password}</p>
                )}
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={loading}
                className={`w-full flex justify-center items-center py-2.5 px-4 border border-transparent rounded-lg shadow-sm text-white bg-[#523680] hover:bg-[#3f2a60] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#523680] transition-colors ${
                  loading ? 'opacity-70 cursor-not-allowed' : ''
                }`}
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  'Sign In'
                )}
              </button>
            </form>
          </div>

          <div className="text-center mt-6 text-sm text-gray-600">
            © 2024 Growwin Capital. All rights reserved.
          </div>
        </div>
      </div>

      <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LoginPage;