
// import React, { useState, useEffect, ChangeEvent } from "react";
// import axios from "axios";

// interface WithdrawRequest {
//   _id: string;
//   email: string;
//   amount: string;
//   address: string;
//   status: string;
//   createdAt: string;
// }

// const Withdraw: React.FC = () => {
//   const [withdrawData, setWithdrawData] = useState<WithdrawRequest[]>([]);
//   const [filteredWithdrawData, setFilteredWithdrawData] = useState<WithdrawRequest[]>([]);
//   const [searchTerm, setSearchTerm] = useState<string>("");
//   const [loading, setLoading] = useState<boolean>(false); // Loader state for API calls
//   const [activeActionId, setActiveActionId] = useState<string | null>(null); // Track the currently active button

//   // Fetch withdraw data
//   const fetchWithdrawData = async (): Promise<void> => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     try {
//       const response = await axios.get(
//         `https://api.growwincapital.com/api/withdraw/getWithdraw`,
//         { headers }
//       );
//       const withdraw: WithdrawRequest[] = Object.values(response.data.Withdraw);
//       setWithdrawData(withdraw);
//       setFilteredWithdrawData(withdraw);
//     } catch (error) {
//       console.error("Error fetching withdraw data: ", error);
//     }
//   };

//   useEffect(() => {
//     fetchWithdrawData();
//   }, []);

//   useEffect(() => {
//     const filteredData = withdrawData.filter((request) =>
//       request.email.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredWithdrawData(filteredData);
//   }, [searchTerm, withdrawData]);

//   const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
//     setSearchTerm(event.target.value);
//   };

//   const handleAction = async (
//     actionType: "approve" | "reject",
//     id: string,
//     email: string,
//     amount?: string
//   ): Promise<void> => {
//     setLoading(true);
//     setActiveActionId(id);

//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     try {
//       if (actionType === "reject" && amount) {
//         // Fetch wallet balance and update wallet
//         const updatedWalletResponse = await axios.get(
//           `https://api.growwincapital.com/api/wallet/walletbalance/${email}`,
//           { headers }
//         );
//         const walletAmount: string = updatedWalletResponse.data.wallet.balance;
//         const finalAmount = parseFloat(walletAmount) + parseFloat(amount);
//         await axios.post(
//           `https://api.growwincapital.com/api/wallet/updateWallet`,
//           {
//             email: email,
//             amount: finalAmount.toString(),
//           },
//           { headers }
//         );
//         // Update withdraw status to rejected
//         await axios.post(
//           `https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`,
//           {
//             email: email,
//             withdrawStatus: "rejected",
//           },
//           { headers }
//         );
//       } else if (actionType === "approve") {
//         // Update withdraw status to success
//         await axios.post(
//           `https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`,
//           {
//             email: email,
//             withdrawStatus: "Success",
//           },
//           { headers }
//         );
//       }

//       // Refresh data after operation
//       await fetchWithdrawData();
//     } catch (error) {
//       console.error(`Error during ${actionType} operation: `, error);
//     } finally {
//       setLoading(false);
//       setActiveActionId(null);
//     }
//   };

//   return (
//     <div>
//       <h1 className="text-2xl font-bold mb-4">Withdrawal Requests</h1>
//       <input
//         type="text"
//         placeholder="Search by email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />
//       <div className="h-[690px] overflow-scroll">
//         <table>
//           <thead>
//             <tr>
//               <th>S.No.</th>
//               <th>User</th>
//               <th>Amount</th>
//               <th>Address</th>
//               <th>Status</th>
//               <th>Time</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {[...filteredWithdrawData].reverse().map((request, index) => (
//               <tr key={request._id}>
//                 <td>{index + 1}</td>
//                 <td>{request.email}</td>
//                 <td>
//                   $
//                   {(
//                     parseFloat(request.amount) -
//                     parseFloat(request.amount) * 0.05
//                   ).toFixed(2)}
//                 </td>
//                 <td>
//                   {request.address}
//                   <button
//                     onClick={() =>
//                       navigator.clipboard.writeText(request.address)
//                     }
//                     className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-light py-[2px] px-2 rounded"
//                   >
//                     Copy
//                   </button>
//                 </td>
//                 <td
//                   className={
//                     request.status === "Pending"
//                       ? "text-blue-600"
//                       : request.status === "rejected"
//                       ? "text-red-500"
//                       : "text-green-600"
//                   }
//                 >
//                   {request.status}
//                 </td>
//                 <td>{request.createdAt}</td>
//                 <td>
//                   {request.status === "Pending" ? (
//                     <>
//                       <button
//                         onClick={() =>
//                           handleAction("approve", request._id, request.email)
//                         }
//                         disabled={loading && activeActionId === request._id}
//                         className="text-white bg-green-500 hover:bg-green-600 px-2 py-1 rounded"
//                       >
//                         {loading && activeActionId === request._id ? (
//                           <span>Loading...</span>
//                         ) : (
//                           "Approve"
//                         )}
//                       </button>
//                       <button
//                         onClick={() =>
//                           handleAction(
//                             "reject",
//                             request._id,
//                             request.email,
//                             request.amount
//                           )
//                         }
//                         disabled={loading && activeActionId === request._id}
//                         className="text-white bg-red-500 hover:bg-red-600 px-3 py-1 rounded"
//                       >
//                         {loading && activeActionId === request._id ? (
//                           <span>Loading...</span>
//                         ) : (
//                           "Reject"
//                         )}
//                       </button>
//                     </>
//                   ) : (
//                     "Done"
//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Withdraw;
import React, { useState, useEffect, ChangeEvent } from "react";
import axios from "axios";

interface WithdrawRequest {
  _id: string;
  email: string;
  amount: string;
  address: string;
  status: string;
  createdAt: string;
}

const Withdraw: React.FC = () => {
  const [withdrawData, setWithdrawData] = useState<WithdrawRequest[]>([]);
  const [filteredWithdrawData, setFilteredWithdrawData] = useState<WithdrawRequest[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("All");
  const [loading, setLoading] = useState<boolean>(false);
  const [activeActionId, setActiveActionId] = useState<string | null>(null);

  // Fetch withdraw data
  const fetchWithdrawData = async (): Promise<void> => {
    try {
      const response = await axios.get(`https://api.growwincapital.com/api/withdraw/getWithdraw`);
      const withdraw: WithdrawRequest[] = Object.values(response.data.Withdraw) as WithdrawRequest[];
withdraw.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      setWithdrawData(withdraw);
      setFilteredWithdrawData(withdraw);
    } catch (error) {
      console.error("Error fetching withdraw data: ", error);
    }
  };

  useEffect(() => {
    fetchWithdrawData();
  }, []);

  // Search & Filter Logic
  useEffect(() => {
    let filtered = withdrawData;

    if (searchTerm) {
      filtered = filtered.filter((request) =>
        request.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (statusFilter !== "All") {
      filtered = filtered.filter((request) => request.status.toLowerCase() === statusFilter.toLowerCase());
    }

    setFilteredWithdrawData(filtered);
  }, [searchTerm, statusFilter, withdrawData]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setStatusFilter(event.target.value);
  };

  const handleAction = async (actionType: "approve" | "reject", id: string, email: string, amount?: string): Promise<void> => {
    setLoading(true);
    setActiveActionId(id);

    try {
      if (actionType === "reject" && amount) {
        const updatedWalletResponse = await axios.get(`https://api.growwincapital.com/api/wallet/walletbalance/${email}`);
        const walletAmount: string = updatedWalletResponse.data.wallet.balance;
        const finalAmount = parseFloat(walletAmount) + parseFloat(amount);

        await axios.post(`https://api.growwincapital.com/api/wallet/updateWallet`, {
          email: email,
          amount: finalAmount.toString(),
        });

        await axios.post(`https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`, {
          email: email,
          withdrawStatus: "rejected",
        });
      } else if (actionType === "approve") {
        await axios.post(`https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`, {
          email: email,
          withdrawStatus: "Success",
        });
      }

      await fetchWithdrawData();
    } catch (error) {
      console.error(`Error during ${actionType} operation: `, error);
    } finally {
      setLoading(false);
      setActiveActionId(null);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Withdrawal Requests</h1>

      {/* Search & Filter Controls */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <input
          type="text"
          placeholder="Search by Email..."
          className="border rounded-lg p-3 w-full md:w-1/2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <select
          className="border rounded-lg p-3 w-full md:w-1/4 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={statusFilter}
          onChange={handleStatusFilterChange}
        >
          <option value="All">All</option>
          <option value="Pending">Pending</option>
          <option value="Success">Success</option>
          <option value="Rejected">Rejected</option>
        </select>
      </div>

      {/* Withdraw Requests List */}
      <div className="space-y-6">
        {filteredWithdrawData.length > 0 ? (
          filteredWithdrawData.map((request: any, index: number) => (
            <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold text-gray-800">{request.email}</h2>
                <span
                  className={`px-3 py-1 rounded-full text-xs font-semibold ${
                    request.status.toLowerCase() === "success"
                      ? "bg-green-100 text-green-700"
                      : request.status.toLowerCase() === "pending"
                      ? "bg-yellow-100 text-yellow-700"
                      : "bg-red-100 text-red-700"
                  }`}
                >
                  {request.status}
                </span>
              </div>

              <div className="mt-3">
                <p className="text-gray-600">
                  <strong>Amount:</strong> <span className="text-green-600 font-bold">${request.amount}</span>
                </p>
                <p className="text-gray-600">
                  <strong>Time:</strong> {new Date(request.createdAt).toLocaleString()}
                </p>
                <p className="text-gray-600">
                  <strong>Address:</strong> {request.address}
                  <button
                    onClick={() => navigator.clipboard.writeText(request.address)}
                    className="ml-2 text-xs bg-blue-500 hover:bg-blue-700 text-white font-light py-[2px] px-2 rounded"
                  >
                    Copy
                  </button>
                </p>
              </div>

              {/* Action Buttons */}
              {request.status === "Pending" && (
                <div className="mt-4 flex space-x-4">
                  <button
                    onClick={() => handleAction("approve", request._id, request.email)}
                    disabled={loading && activeActionId === request._id}
                    className="text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded"
                  >
                    {loading && activeActionId === request._id ? "Loading..." : "Approve"}
                  </button>
                  <button
                    onClick={() => handleAction("reject", request._id, request.email, request.amount)}
                    disabled={loading && activeActionId === request._id}
                    className="text-white bg-red-500 hover:bg-red-600 px-4 py-2 rounded"
                  >
                    {loading && activeActionId === request._id ? "Loading..." : "Reject"}
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">No withdrawals found.</p>
        )}
      </div>
    </div>
  );
};

export default Withdraw;
