

// import React, { ReactNode } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import {
//   LayoutDashboard,
//   Users,
//   UserPlus,
//   Wallet,
//   History,
//   BadgeDollarSign,
//   ArrowLeftRight,
//   Upload,
//   Download,
//   ImagePlus,
//   FileCheck,
//   Ticket,
//   LogOut,
//   Share2,
//   Users2,
//   PiggyBank,
//   Diamond,
//   LucideIcon,
//   MessageCircle,
//   Tag
// } from "lucide-react";


// interface NavItemProps {
//   to: string;
//   icon: LucideIcon;
//   children: ReactNode;
// }

// interface NavGroupProps {
//   title: string;
//   children: ReactNode;
// }

// const Sidebar: React.FC = () => {
//   const navigate = useNavigate();

//   const handleLogout = (): void => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("email");
//     navigate("/login");
//   };

//   const NavItem: React.FC<NavItemProps> = ({ to, icon: Icon, children }) => (
//     <NavLink
//       to={to}
//       className={({ isActive }) =>
//         `flex items-center gap-3 py-2.5 px-4 rounded-lg transition-all duration-200 hover:bg-[#523680]/20 ${
//           isActive ? "bg-[#523680] text-white hover:bg-[#523680]" : "text-gray-600"
//         }`
//       }
//     >
//       <Icon size={20} />
//       <span className="text-sm font-medium">{children}</span>
//     </NavLink>
//   );

//   const NavGroup: React.FC<NavGroupProps> = ({ title, children }) => (
//     <div className="mb-4">
//       <h3 className="text-xs font-semibold text-gray-400 uppercase px-4 mb-2">{title}</h3>
//       {children}
//     </div>
//   );

//   return (
//     <div className="w-64 h-screen bg-white shadow-lg flex flex-col">
//       {/* Logo Section */}
//       <div className="p-4 border-b border-gray-100">
//         <div className="flex items-center gap-3">
//           {/* <img src="/assets/logo.png" alt="Logo" className="h-12 w-12" /> */}
//           <div>
//             <h2 className="text-lg font-bold text-[#523680]">
//               Growwin<span className="text-[#b9aecc]">capital</span>
//             </h2>
//             <p className="text-xs text-[#b9aecc]">Investment Platform</p>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section - Scrollable */}
//       <div className="flex-1 overflow-y-auto px-2 py-4 space-y-2">
//         <NavGroup title="Main">
//           <NavItem to="/" icon={LayoutDashboard}>Dashboard</NavItem>
//           <NavItem to="/users" icon={Users}>Users List</NavItem>
//           <NavItem to="/teamDetails" icon={Users2}>Team Details</NavItem>
//         </NavGroup>

//         <NavGroup title="Financial">
//           <NavItem to="/teamincome" icon={BadgeDollarSign}>Team Income</NavItem>
//           <NavItem to="/income" icon={PiggyBank}>Self Income</NavItem>
//           <NavItem to="/referal" icon={Share2}>Referal Income</NavItem>
//           <NavItem to="/platinumIncome" icon={Wallet}>Platinum Income</NavItem>
//           <NavItem to="/platinumIncomeTeam" icon={Wallet}>Platinum Team Income</NavItem>
//           <NavItem to="/transferDetails" icon={ArrowLeftRight}>Transfer Details</NavItem>
//           <NavItem to="/wallet" icon={Wallet}>User Wallet</NavItem>
//         </NavGroup>

//         <NavGroup title="Investments">
//           <NavItem to="/invest" icon={BadgeDollarSign}>Invested Amount</NavItem>
//           <NavItem to="/investHistory" icon={History}>Investment History</NavItem>
//           <NavItem to="/platinumData" icon={Diamond}>Platinum Data</NavItem>
//         </NavGroup>

//         <NavGroup title="Transactions">
//           <NavItem to="/deposit" icon={Upload}>Deposit Details</NavItem>
//           <NavItem to="/withdraw" icon={Download}>Withdraw Requests</NavItem>
//           <NavItem to="/withdrawData" icon={History}>Withdraw Data</NavItem>
//         </NavGroup>

//         <NavGroup title="Other">
//         <NavItem to="/appVersion" icon={Tag}>App Version</NavItem>
//         <NavItem to="/notification" icon={MessageCircle}>Notification</NavItem>
//           <NavItem to="/banner" icon={ImagePlus}>Banner Placement</NavItem>
//           <NavItem to="/kyc" icon={FileCheck}>User KYC</NavItem>
//           <NavItem to="/ticket" icon={Ticket}>Ticket</NavItem>
//           <NavItem to="/roles" icon={Ticket}>Roles & Responsibilities</NavItem>
//         </NavGroup>
//       </div>

//       {/* Logout Button - Fixed at Bottom */}
//       <div className="border-t border-gray-100 p-4">
//         <button
//           onClick={handleLogout}
//           className="flex items-center justify-center gap-2 w-full py-2.5 px-4 rounded-lg text-white bg-[#523680] hover:bg-[#523680]/90 transition-colors duration-200"
//         >
//           <LogOut size={20} />
//           <span className="font-medium text-white">Logout</span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;
import React, { ReactNode, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  LayoutDashboard,
  Users,
  UserPlus,
  Wallet,
  History,
  BadgeDollarSign,
  ArrowLeftRight,
  Upload,
  Download,
  ImagePlus,
  FileCheck,
  Ticket,
  LogOut,
  Share2,
  Users2,
  PiggyBank,
  Diamond,
  LucideIcon,
  MessageCircle,
  Tag,
  Shield,
  MessageCircleQuestion
} from "lucide-react";

interface NavItemProps {
  to: string;
  icon: LucideIcon;
  children: ReactNode;
}

interface NavGroupProps {
  title: string;
  children: ReactNode;
}

interface SidebarLinkData {
  path: string;
  label: string;
  icon: LucideIcon;
  group: string;
}

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [userPermissions, setUserPermissions] = useState<string[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  useEffect(() => {
    // Load user permissions from localStorage
    const permissionsStr = localStorage.getItem("permissions");
    if (permissionsStr) {
      try {
        const permissions = JSON.parse(permissionsStr);
        setUserPermissions(permissions);
      } catch (error) {
        console.error("Error parsing permissions:", error);
        setUserPermissions([]);
      }
    }

    // Load user name
    const name = localStorage.getItem("name");
    setUserName(name || "Admin User");

    // Check if super admin
    const superAdmin = localStorage.getItem("isSuperAdmin") === "true";
    setIsSuperAdmin(superAdmin);
  }, []);

  const handleLogout = (): void => {
    // Clear all localStorage items
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("permissions");
    localStorage.removeItem("isSuperAdmin");
    
    // Navigate to login page
    navigate("/login");
  };

  // All available sidebar links with their grouping
  const allSidebarLinks: SidebarLinkData[] = [
    { path: "/", label: "Dashboard", icon: LayoutDashboard, group: "Main" },
    { path: "/users", label: "Users List", icon: Users, group: "Main" },
    { path: "/teamDetails", label: "Team Details", icon: Users2, group: "Main" },
    
    { path: "/teamincome", label: "Team Income", icon: BadgeDollarSign, group: "Financial" },
    { path: "/income", label: "Self Income", icon: PiggyBank, group: "Financial" },
    { path: "/referal", label: "Referal Income", icon: Share2, group: "Financial" },
    { path: "/platinumIncome", label: "Platinum Income", icon: Wallet, group: "Financial" },
    { path: "/platinumIncomeTeam", label: "Platinum Team Income", icon: Wallet, group: "Financial" },
    { path: "/transferDetails", label: "Transfer Details", icon: ArrowLeftRight, group: "Financial" },
    { path: "/wallet", label: "User Wallet", icon: Wallet, group: "Financial" },
    
    { path: "/invest", label: "Invested Amount", icon: BadgeDollarSign, group: "Investments" },
    { path: "/investHistory", label: "Investment History", icon: History, group: "Investments" },
    { path: "/platinumData", label: "Platinum Data", icon: Diamond, group: "Investments" },
    
    { path: "/deposit", label: "Deposit Details", icon: Upload, group: "Transactions" },
    { path: "/withdraw", label: "Withdraw Requests", icon: Download, group: "Transactions" },
    { path: "/withdrawData", label: "Withdraw Data", icon: History, group: "Transactions" },
    
    { path: "/appVersion", label: "App Version", icon: Tag, group: "Other" },
    { path: "/notification", label: "Notification", icon: MessageCircle, group: "Other" },
    { path: "/banner", label: "Banner Placement", icon: ImagePlus, group: "Other" },
    { path: "/kyc", label: "User KYC", icon: FileCheck, group: "Other" },
    { path: "/ticket", label: "Ticket", icon: Ticket, group: "Other" },
    { path: "/roles", label: "Roles & Responsibilities", icon: Shield, group: "Other" },
    { path: "/blogs", label: "Blogs", icon: MessageCircleQuestion, group: "Other" }
    
  ];

  // Filter sidebar links based on user permissions
  const getFilteredLinks = (group: string): SidebarLinkData[] => {
    return allSidebarLinks.filter(link => 
      link.group === group && (isSuperAdmin || userPermissions.includes(link.path))
    );
  };

  const NavItem: React.FC<NavItemProps> = ({ to, icon: Icon, children }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center gap-3 py-2.5 px-4 rounded-lg transition-all duration-200 hover:bg-[#523680]/20 ${
          isActive ? "bg-[#523680] text-white hover:bg-[#523680]" : "text-gray-600"
        }`
      }
    >
      <Icon size={20} />
      <span className="text-sm font-medium">{children}</span>
    </NavLink>
  );

  const NavGroup: React.FC<NavGroupProps> = ({ title, children }) => (
    <div className="mb-4">
      <h3 className="text-xs font-semibold text-gray-400 uppercase px-4 mb-2">{title}</h3>
      {children}
    </div>
  );

  // Function to render nav groups only if they have visible items
  const renderNavGroup = (title: string, groupName: string) => {
    const filteredLinks = getFilteredLinks(groupName);
    
    if (filteredLinks.length === 0) {
      return null;
    }
    
    return (
      <NavGroup title={title}>
        {filteredLinks.map(link => (
          <NavItem key={link.path} to={link.path} icon={link.icon}>
            {link.label}
          </NavItem>
        ))}
      </NavGroup>
    );
  };

  return (
    <div className="w-64 h-screen bg-white shadow-lg flex flex-col">
      {/* Logo Section */}
      <div className="p-4 border-b border-gray-100">
        <div className="flex items-center gap-3">
          <div>
            <h2 className="text-lg font-bold text-[#523680]">
              Growwin<span className="text-[#b9aecc]">capital</span>
            </h2>
            <p className="text-xs text-[#b9aecc]">Investment Platform</p>
          </div>
        </div>
      </div>

      {/* User Info Section */}
      <div className="px-4 py-3 border-b border-gray-100">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-full bg-[#523680] flex items-center justify-center text-white font-semibold">
            {userName.charAt(0).toUpperCase()}
          </div>
          <div>
            <p className="text-sm font-medium text-gray-800">{userName}</p>
            <p className="text-xs text-gray-500">
              {isSuperAdmin ? "Super Admin" : "Admin"}
            </p>
          </div>
        </div>
      </div>

      {/* Navigation Section - Scrollable */}
      <div className="flex-1 overflow-y-auto px-2 py-4 space-y-2">
        {renderNavGroup("Main", "Main")}
        {renderNavGroup("Financial", "Financial")}
        {renderNavGroup("Investments", "Investments")}
        {renderNavGroup("Transactions", "Transactions")}
        {renderNavGroup("Other", "Other")}
      </div>

      {/* Logout Button - Fixed at Bottom */}
      <div className="border-t border-gray-100 p-4">
        <button
          onClick={handleLogout}
          className="flex items-center justify-center gap-2 w-full py-2.5 px-4 rounded-lg text-white bg-[#523680] hover:bg-[#523680]/90 transition-colors duration-200"
        >
          <LogOut size={20} />
          <span className="font-medium text-white">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;