

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Search, ChevronLeft, ChevronRight, Filter, RefreshCw } from "lucide-react";

interface IncomeItem {
  _id: string;
  email: string;
  income: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  limit: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

interface ApiResponse {
  status: string;
  cached: boolean;
  data: {
    income: IncomeItem[];
    pagination: PaginationInfo;
  };
}

const PlatinumIncome: React.FC = () => {
  const [incomeData, setIncomeData] = useState<IncomeItem[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo>({
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("date");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [filterAmount, setFilterAmount] = useState<string>("");

  // Fetch income data
  const fetchIncomeData = async (page = 1, limit = 10) => {
    setIsLoading(true);
    try {
      const response = await axios.get<ApiResponse>(`https://api.growwincapital.com/api/platinum/admin/all-income?page=${page}&limit=${limit}`);
      setIncomeData(response.data.data.income);
      setPagination(response.data.data.pagination);
      setError(null);
    } catch (err) {
      setError("Failed to load income data. Please try again.");
      console.error("Error fetching income data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIncomeData(pagination.currentPage);
  }, []);

  // Handle page navigation
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= pagination.totalPages) {
      fetchIncomeData(newPage);
    }
  };

  // Handle search
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Handle refresh
  const handleRefresh = () => {
    fetchIncomeData(pagination.currentPage);
  };

  // Handle filter amount
  const handleFilterAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterAmount(e.target.value);
  };

  // Handle sort change
  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortBy(e.target.value);
  };

  // Toggle sort order
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Filter and sort the data
  const filteredAndSortedData = incomeData
    .filter(item => {
      const matchesSearch = item.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFilter = filterAmount === "" || 
        (parseFloat(item.income) >= parseFloat(filterAmount));
      return matchesSearch && matchesFilter;
    })
    .sort((a, b) => {
      if (sortBy === "date") {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (sortBy === "amount") {
        const amountA = parseFloat(a.income);
        const amountB = parseFloat(b.income);
        return sortOrder === "asc" ? amountA - amountB : amountB - amountA;
      } else if (sortBy === "email") {
        return sortOrder === "asc" 
          ? a.email.localeCompare(b.email) 
          : b.email.localeCompare(a.email);
      }
      return 0;
    });

  // Format date for display
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="max-w-6xl mx-auto p-4 md:p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-2">Platinum Income Dashboard</h1>
        <p className="text-gray-600">Manage and monitor all platinum income payments</p>
      </div>

      {/* Controls Bar */}
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="relative flex-grow">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search by email..."
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        <div className="flex space-x-3">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Filter className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="number"
              placeholder="Min. amount"
              className="block w-32 pl-9 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={filterAmount}
              onChange={handleFilterAmount}
            />
          </div>

          <select
            className="block px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={sortBy}
            onChange={handleSortChange}
          >
            <option value="date">Sort by Date</option>
            <option value="amount">Sort by Amount</option>
            <option value="email">Sort by Email</option>
          </select>

          <button
            className="p-2 border border-gray-300 rounded-lg hover:bg-gray-100"
            onClick={toggleSortOrder}
            aria-label={`Sort ${sortOrder === "asc" ? "ascending" : "descending"}`}
          >
            {sortOrder === "asc" ? "↑" : "↓"}
          </button>

          <button
            className="p-2 border border-gray-300 rounded-lg hover:bg-gray-100 flex items-center gap-1"
            onClick={handleRefresh}
          >
            <RefreshCw className="h-4 w-4" />
          </button>
        </div>
      </div>

      {/* Results summary */}
      <div className="flex justify-between mb-4 text-sm text-gray-600">
        <div>
          Showing {filteredAndSortedData.length} of {pagination.totalRecords} entries
        </div>
        {filterAmount && (
          <div>
            Filter: Amount ≥ {filterAmount}
          </div>
        )}
      </div>

      {/* Income Cards - List View */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 p-4 rounded-lg text-red-600 text-center">
          {error}
        </div>
      ) : (
        <div className="space-y-4">
          {filteredAndSortedData.map((item) => (
            <div key={item._id} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow border border-gray-100 overflow-hidden">
              <div className="p-5">
                <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                  <div className="mb-3 md:mb-0">
                    <h3 className="font-semibold text-gray-800">
                      {item.email}
                    </h3>
                    <p className="text-sm text-gray-500">
                      Created: {formatDate(item.createdAt)}
                    </p>
                    {item.updatedAt !== item.createdAt && (
                      <p className="text-sm text-gray-500">
                        Updated: {formatDate(item.updatedAt)}
                      </p>
                    )}
                  </div>
                  <div className="bg-blue-50 text-blue-700 px-4 py-2 rounded-full font-medium text-lg self-start md:self-auto">
                    ${parseFloat(item.income).toFixed(2)}
                  </div>
                </div>
                <div className="text-sm mt-4 pt-3 border-t border-gray-100">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <span className="text-gray-600"><strong>ID:</strong> {item._id}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* No results */}
      {!isLoading && filteredAndSortedData.length === 0 && (
        <div className="bg-gray-50 p-8 rounded-lg text-center">
          <p className="text-gray-600">No income records found matching your criteria.</p>
        </div>
      )}

      {/* Pagination */}
      <div className="flex justify-between items-center mt-8">
        <div className="text-sm text-gray-600">
          Page {pagination.currentPage} of {pagination.totalPages}
        </div>
        <div className="flex space-x-2">
          <button
            className={`p-2 rounded-lg border ${!pagination.hasPrevPage ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'hover:bg-gray-100 text-gray-700'}`}
            onClick={() => handlePageChange(pagination.currentPage - 1)}
            disabled={!pagination.hasPrevPage}
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          
          {/* Page numbers */}
          <div className="flex space-x-1">
            {Array.from({ length: Math.min(5, pagination.totalPages) }, (_, i) => {
              let pageNumber;
              if (pagination.totalPages <= 5) {
                pageNumber = i + 1;
              } else if (pagination.currentPage <= 3) {
                pageNumber = i + 1;
              } else if (pagination.currentPage >= pagination.totalPages - 2) {
                pageNumber = pagination.totalPages - 4 + i;
              } else {
                pageNumber = pagination.currentPage - 2 + i;
              }
              
              return (
                <button
                  key={i}
                  className={`h-9 w-9 flex items-center justify-center rounded-lg ${
                    pagination.currentPage === pageNumber
                      ? 'bg-blue-600 text-white'
                      : 'border hover:bg-gray-100 text-gray-700'
                  }`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            })}
          </div>
          
          <button
            className={`p-2 rounded-lg border ${!pagination.hasNextPage ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'hover:bg-gray-100 text-gray-700'}`}
            onClick={() => handlePageChange(pagination.currentPage + 1)}
            disabled={!pagination.hasNextPage}
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlatinumIncome;