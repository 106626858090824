// import { useState, useEffect } from "react";
// import axios from "axios";

// const Platinum = () => {
//   const [depositData, setDepositData] = useState<any>([]);
//   console.log(depositData)
//   const [status, setStatus] = useState(false);
//   const [debitValues, setDebitValues] = useState<Record<string, string>>({});
//   const [creditValues, setCreditValues] = useState<Record<string, string>>({});
//     const [searchTerm, setSearchTerm] = useState(''); // State for the search term


//   useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     axios
//       .get(`https://api.growwincapital.com/api/platinum/admin/investors`, { headers })
//       .then((response) => {
//         console.log(response.data);
//         const deposit: any = Object.values(response.data);
//         setDepositData(deposit);
//       })
//       .catch((error) => {
//         console.log("no error " + error);
//       });
//   }, [status]);

//   const handleDebit = async (email: string, amount: any,incomdate:any) => {
//     const headers = {
//       'Content-Type': 'application/json',
//     };
  
//     // Find the index of the user in the depositData array
//     const index = depositData.findIndex((user: any) => user.email === email);
//     if (index !== -1) {
//       const currentInvestment = parseFloat(depositData[index].totalAmount);
//       const debitAmount = parseFloat(amount);
//       let newInvestment = Math.max(0, currentInvestment - debitAmount);
//       if (newInvestment < 20) {
//         newInvestment = 20;
//       }  
//       const formattedNewInvestment = newInvestment.toFixed(2);
//     let  subscription = 'Platinum';
//       try {
//         const response = await fetch(`https://api.growwincapital.com/api/platinum/update`, {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({
//             email: email,
//             totalAmount:formattedNewInvestment,
//             incomeDate:incomdate,
//             subscription: subscription,
//             amount:debitAmount.toFixed(2),
//             type:"debit"
//           }),
//         });
    
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         const data = await response.json();
//         console.log(data);
    
//         // Update the depositData state to reflect the debit operation
//         const updatedDepositData = [...depositData];
//         updatedDepositData[index].totalAmount = formattedNewInvestment;
//         updatedDepositData[index].subscription = subscription; 
//         setDepositData(updatedDepositData);
    
//         // Reset the debit input field for this user
//         setDebitValues((prevValues) => ({
//           ...prevValues,
//           [email]: '',
//         }));
//       } catch (error) {
//         console.error('Error updating wallet:', error);
//       }
//     }
//   };
  

  
//   const handleCredit = async (email: string, amount: any,incomdate:any) => {
//     const headers = {
//       'Content-Type': 'application/json',
//     };
//     // Find the index of the user in the depositData array
//     const index = depositData.findIndex((user: any) => user.email === email);
//     if (index !== -1) {
//       const currentInvestment = parseFloat(depositData[index].totalAmount);
//       const creditAmount = parseFloat(amount); 
//       const newInvestment = currentInvestment + creditAmount;
//       const formattedNewInvestment = newInvestment.toFixed(2);
//       let  subscription = 'Platinum';
//       try {
     
//         const response = await fetch('https://api.growwincapital.com/api/platinum/update', {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({
//             email: email,
//             totalAmount:  formattedNewInvestment,
//             incomeDate:incomdate,
//             subscription: subscription,
//             amount:creditAmount.toFixed(2),
//             type:"credit"
//           }),
//         });
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         const data = await response.json();
//         console.log(data);
//         // Update the depositData state to reflect the credit operation
//         const updatedDepositData = [...depositData];
//         updatedDepositData[index].totalAmount = formattedNewInvestment;
//         updatedDepositData[index].subscription = subscription; 
//         setDepositData(updatedDepositData);
    
//         // Reset the credit input field for this user
//         setCreditValues((prevValues) => ({
//           ...prevValues,
//           [email]: '',
//         }));
//       } catch (error) {
//         console.error('Error updating wallet:', error);
//       }
//     }
//   };

//  // Handle the closure of an investment
//  const handleCloseInvestment = async (email: string) => {
//   const headers = {
//     'Content-Type': 'application/json',
//   };

//   try {
//     const response = await fetch(`https://api.growwincapital.com/api/invest/close-investment/${email}`, {
//       method: 'PUT',
//       headers: headers,
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();
//     console.log(data);

//     // Update the state to show that the investment is closed
//     const updatedDepositData = depositData.map((user: any) =>
//       user.email === email ? { ...user, isClosed: true } : user
//     );
//     setDepositData(updatedDepositData);
//   } catch (error) {
//     console.error('Error closing investment:', error);
//   }
// };
//   const handleInputChange = (email: any, value: any, type: string) => {
//     if (type === 'debit') {
//       setDebitValues(prevValues => ({
//         ...prevValues,
//         [email]: value,
//       }));
//     } else if (type === 'credit') {
//       setCreditValues(prevValues => ({
//         ...prevValues,
//         [email]: value,
//       }));
//     }
//   };  // Function to handle search term changes
//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filter depositData based on the search term
//   const filteredDepositData = depositData.filter((item:any) =>
//     item.email.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div>
//       <h1 className="text-2xl font-bold mb-4">Platinum Invest</h1>
//       <input
//         type="text"
//         placeholder="Search by email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />

//       <div className="h-[690px] overflow-scroll">
//         <table>
//           <thead>
//             <tr>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Investment Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Closing Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Invest Amount</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Subscription</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Update Investment</th>
//             </tr>
//           </thead>
//           <tbody>
//             {[...depositData].reverse().map((request: any, index: any) => (
//               <tr key={index + 1}>
//                 <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{request.email} </td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//   {new Date(request.createdAt).toLocaleDateString('en-GB', {
//     day: '2-digit',
//     month: 'short',
//     year: 'numeric',
//   }).replace(/ /g, '/')}
// </td>
// <td className="py-3 px-8 border-b border-gray-200">
//   {new Date(request.incomeDate).toLocaleDateString('en-GB', {
//     day: '2-digit',
//     month: 'short',
//     year: 'numeric',
//   }).replace(/ /g, '/')}
// </td>
// <td className="py-3 px-8 border-b border-gray-200">
//   {new Date(request.closingDate).toLocaleDateString('en-GB', {
//     day: '2-digit',
//     month: 'short',
//     year: 'numeric',
//   }).replace(/ /g, '/')}
// </td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//   {request.isClosed?0:Number(request.totalAmount).toFixed(2)}
// </td>
// <td className="py-3 px-8 border-b border-gray-200">{request.subscription}  </td>
// <td>
//                   {/* Conditionally render Debit/Credit if investment is not closed */}
//                   {!request.isClosed ? (
//                     <>
//                      <td className="py-4 px-6 flex items-center">
//   <input
//     type="text"
//     value={debitValues[request.email] || ''}
//     onChange={(e) => handleInputChange(request.email, e.target.value, 'debit')}
//     style={{
//       marginRight: '8px', // Add space between input and button
//       border: '1px solid #ccc', // Add border for visibility
//     }}
//     className="text-field-class"
//   />
//   <button
//     onClick={() => handleDebit(request.email, debitValues[request.email],new Date(request.incomeDate))}
//     style={{ 
//       backgroundColor: 'red', 
//       color: 'white',
//       padding: '8px 16px', // Add padding to the text inside the button
//       borderRadius: '8px', // Round the corners of the button
//       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
//       border: 'none', // Remove the default button border
//     }}
//     className="update-button-class"
//   >
//     Debit
//   </button>
// </td>
// <td className="py-4 px-6 flex items-center">
//   <input
//     type="text"
//     value={creditValues[request.email] || ''}
//     onChange={(e) => handleInputChange(request.email, e.target.value, 'credit')}
//     style={{
//       marginRight: '8px', // Add space between input and button
//       border: '1px solid #ccc', // Add border for visibility
//     }}
//     className="text-field-class"
//   />
//   <button
//     onClick={() => handleCredit(request.email, creditValues[request.email],new Date(request.incomeDate))}
//     style={{ 
//       backgroundColor: 'green', 
//       color: 'white',
//       padding: '8px 16px', // Add padding to the text inside the button
//       borderRadius: '8px', // Round the corners of the button
//       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
//       border: 'none', // Remove the default button border
//     }}
//     className="update-button-class"
//   >
//     Credit
//   </button>
// </td>
//                     </>
//                   ) : (
//                     <span>Closed</span> // Display "Closed" if the investment is closed
//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Platinum;
import { useState, useEffect } from "react";
import axios from "axios";

const Platinum = () => {
  const [depositData, setDepositData] = useState<any[]>([]);
  const [debitValues, setDebitValues] = useState<Record<string, string>>({});
  const [creditValues, setCreditValues] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get(`https://api.growwincapital.com/api/platinum/admin/investors`)
      .then((response) => {
        const sortedData = Object.values(response.data).sort(
          (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setDepositData(sortedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleTransaction = async (email: string, amount: string, incomeDate: string, type: "debit" | "credit") => {
    if (!amount) return;
    try {
      const updatedAmount = type === "debit" ? -parseFloat(amount) : parseFloat(amount);
      const updatedDepositData = depositData.map((user) =>
        user.email === email ? { ...user, totalAmount: (Number(user.totalAmount) + updatedAmount).toFixed(2) } : user
      );
      setDepositData(updatedDepositData);

      await axios.post("https://api.growwincapital.com/api/platinum/update", {
        email,
        totalAmount: (Number(updatedDepositData.find((user) => user.email === email)?.totalAmount) || 0).toFixed(2),
        incomeDate,
        subscription: "Platinum",
        amount: parseFloat(amount).toFixed(2),
        type,
      });

      type === "debit" ? setDebitValues((prev) => ({ ...prev, [email]: "" })) : setCreditValues((prev) => ({ ...prev, [email]: "" }));
    } catch (error) {
      console.error(`Error processing ${type}:`, error);
    }
  };

  const handleCloseInvestment = async (email: string) => {
    try {
      await axios.put(`https://api.growwincapital.com/api/invest/close-investment/${email}`);
      setDepositData((prev) => prev.map((user) => (user.email === email ? { ...user, isClosed: true } : user)));
    } catch (error) {
      console.error("Error closing investment:", error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Platinum Invest</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by email..."
        className="border rounded-lg p-3 w-full mb-6 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Investors List */}
      <div className="space-y-6">
        {depositData
          .filter((user) => user.email.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((user, index) => (
            <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold text-gray-800">{user.email}</h2>
                <span
                  className={`px-3 py-1 rounded-full text-xs font-semibold ${
                    user.isClosed ? "bg-red-100 text-red-700" : "bg-green-100 text-green-700"
                  }`}
                >
                  {user.isClosed ? "Closed" : "Active"}
                </span>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-3">
                <p className="text-gray-600">
                  <strong>Investment Date:</strong> {new Date(user.createdAt).toLocaleDateString("en-GB")}
                </p>
                <p className="text-gray-600">
                  <strong>Income Date:</strong> {new Date(user.incomeDate).toLocaleDateString("en-GB")}
                </p>
                <p className="text-gray-600">
                  <strong>Closing Date:</strong> {new Date(user.closingDate).toLocaleDateString("en-GB")}
                </p>
                <p className="text-gray-600">
                  <strong>Invest Amount:</strong> ${user.isClosed ? "0.00" : Number(user.totalAmount).toFixed(2)}
                </p>
                <p className="text-gray-600">
                  <strong>Subscription:</strong> {user.subscription}
                </p>
              </div>

              {/* Action Buttons */}
              {!user.isClosed ? (
                <div className="mt-4 flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0">
                  {/* Debit Input & Button */}
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={debitValues[user.email] || ""}
                      onChange={(e) => setDebitValues((prev) => ({ ...prev, [user.email]: e.target.value }))}
                      className="border rounded-lg p-2 w-28 text-center"
                      placeholder="Amount"
                    />
                    <button
                      onClick={() => handleTransaction(user.email, debitValues[user.email], user.incomeDate, "debit")}
                      className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 transition"
                    >
                      Debit
                    </button>
                  </div>

                  {/* Credit Input & Button */}
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={creditValues[user.email] || ""}
                      onChange={(e) => setCreditValues((prev) => ({ ...prev, [user.email]: e.target.value }))}
                      className="border rounded-lg p-2 w-28 text-center"
                      placeholder="Amount"
                    />
                    <button
                      onClick={() => handleTransaction(user.email, creditValues[user.email], user.incomeDate, "credit")}
                      className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition"
                    >
                      Credit
                    </button>
                  </div>

                  {/* Close Investment Button */}
                  <button
                    onClick={() => handleCloseInvestment(user.email)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow hover:bg-gray-600 transition"
                  >
                    Close Investment
                  </button>
                </div>
              ) : (
                <p className="text-gray-500 text-center mt-4">Investment Closed</p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Platinum;
