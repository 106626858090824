import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  CheckCircle, 
  XCircle, 
  Edit, 
  Trash2, 
  Plus, 
  Save, 
  X, 
  Users,
  Search
} from 'lucide-react';
import { Alert, AlertColor, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

interface AdminUser {
  _id: string;
  name: string;
  email: string;
  permissions: string[];
  active: boolean;
  createdBy: string;
  createdAt: string;
}

interface FormData {
  name: string;
  email: string;
  password: string;
  number: string;
  permissions: string[];
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const Roles: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    password: '',
    number: '',
    permissions: []
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [snackbar, setSnackbar] = useState<SnackbarState>({ 
    open: false, 
    message: '', 
    severity: 'success' 
  });
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [editingUser, setEditingUser] = useState<string | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<{ open: boolean, userId: string | null }>({
    open: false,
    userId: null
  });
  const [checkingPermissions, setCheckingPermissions] = useState<boolean>(false);

  // Base URL for API
  const API_BASE_URL = 'https://api.growwincapital.com/api/roles';

  // All available permissions based on your routes
  const availablePermissions = [
    { path: '/', label: 'Dashboard' },
    { path: '/users', label: 'Users Management' },
    { path: '/teamDetails', label: 'Team Details' },
    { path: '/teamincome', label: 'Team Income Analytics' },
    { path: '/income', label: 'Personal Income' },
    { path: '/referal', label: 'Referral Program' },
    { path: '/transferDetails', label: 'Transfer History' },
    { path: '/wallet', label: 'Digital Wallet' },
    { path: '/invest', label: 'Investment Portal' },
    { path: '/investHistory', label: 'Investment History' },
    { path: '/deposit', label: 'Deposit Management' },
    { path: '/withdraw', label: 'Withdrawal Requests' },
    { path: '/withdrawData', label: 'Withdrawal Analytics' },
    { path: '/banner', label: 'Banner Management' },
    { path: '/kyc', label: 'KYC Verification' },
    { path: '/ticket', label: 'Support Tickets' },
    { path: '/platinumData', label: 'Platinum Members' },
    { path: '/platinumIncome', label: 'Platinum Income' },
    { path: '/platinumIncomeTeam', label: 'Platinum Team Income' },
    { path: '/notification', label: 'Notification Management' },
    { path: '/appVersion', label: 'App Version Management' },
    { path: '/roles', label: 'Roles & Responsibilities' },
    { path: '/blogs', label: 'Blogs' }
  ];

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  const fetchAdminUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.data.status === 'success') {
        setAdminUsers(response.data.data.adminRoles);
      }
    } catch (error: any) {
      showSnackbar(error.response?.data?.message || 'Failed to fetch admin users', 'error');
    } finally {
      setLoading(false);
    }
  };

  const checkUserPermissions = async (email: string) => {
    setCheckingPermissions(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_BASE_URL}/permissions`,
        { email },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.data.status === 'success' && response.data.data.permissions) {
        // Update form data with the retrieved permissions
        setFormData(prev => ({
          ...prev,
          permissions: response.data.data.permissions
        }));
        
        showSnackbar('User permissions loaded successfully', 'success');
      }
    } catch (error: any) {
      showSnackbar(error.response?.data?.message || 'Failed to fetch user permissions', 'error');
    } finally {
      setCheckingPermissions(false);
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
    
    if (!editingUser) {
      if (!formData.password.trim()) newErrors.password = 'Password is required';
      else if (formData.password.length < 8) newErrors.password = 'Password must be at least 8 characters';
      
      if (!formData.number.trim()) newErrors.number = 'Number is required';
    }
    
    if (formData.permissions.length === 0) newErrors.permissions = 'Select at least one permission';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handlePermissionToggle = (path: string) => {
    setFormData(prev => {
      const permissions = [...prev.permissions];
      
      if (permissions.includes(path)) {
        // Remove permission
        return { ...prev, permissions: permissions.filter(p => p !== path) };
      } else {
        // Add permission
        return { ...prev, permissions: [...permissions, path] };
      }
    });
    
    // Clear permissions error if any
    if (errors.permissions) {
      setErrors(prev => ({ ...prev, permissions: '' }));
    }
  };

  const handleCreate = async () => {
    if (!validateForm()) return;
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        API_BASE_URL, 
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.data.status === 'success') {
        showSnackbar('Admin user created successfully', 'success');
        fetchAdminUsers();
        resetForm();
      }
    } catch (error: any) {
      showSnackbar(error.response?.data?.message || 'Failed to create admin user', 'error');
    }
  };

  const handleUpdate = async () => {
    if (!validateForm() || !editingUser) return;
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.patch(
        `${API_BASE_URL}/${editingUser}`, 
        { permissions: formData.permissions },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.data.status === 'success') {
        showSnackbar('Admin user updated successfully', 'success');
        fetchAdminUsers();
        setEditingUser(null);
        resetForm();
      }
    } catch (error: any) {
      showSnackbar(error.response?.data?.message || 'Failed to update admin user', 'error');
    }
  };

  const handleDelete = async () => {
    if (!deleteDialog.userId) return;
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${API_BASE_URL}/${deleteDialog.userId}`, 
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.status === 204) {
        showSnackbar('Admin user deleted successfully', 'success');
        fetchAdminUsers();
        setDeleteDialog({ open: false, userId: null });
      }
    } catch (error: any) {
      showSnackbar(error.response?.data?.message || 'Failed to delete admin user', 'error');
    }
  };

  const handleEditClick = (user: AdminUser) => {
    setEditingUser(user._id);
    setFormData({
      name: user.name,
      email: user.email,
      password: '',
      number: '',
      permissions: user.permissions
    });
    setShowCreateForm(true);
  };

  const handleCheckPermissions = () => {
    if (formData.email) {
      checkUserPermissions(formData.email);
    } else {
      setErrors(prev => ({ ...prev, email: 'Email is required to check permissions' }));
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      password: '',
      number: '',
      permissions: []
    });
    setErrors({});
    setShowCreateForm(false);
    setEditingUser(null);
  };

  const showSnackbar = (message: string, severity: AlertColor) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const filteredAdminUsers = adminUsers.filter(user => {
    const searchText = filterText.toLowerCase();
    return (
      user.name.toLowerCase().includes(searchText) ||
      user.email.toLowerCase().includes(searchText)
    );
  });

  return (
    <div className="flex flex-col h-full">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-2">Admin Roles Management</h1>
        <p className="text-gray-600">
          Create and manage admin users with specific access permissions
        </p>
      </div>

      {/* Top Action Bar */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
        <div className="relative w-full md:w-64">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search admin users..."
            className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#523680]"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        
        <button
          onClick={() => setShowCreateForm(!showCreateForm)}
          className="flex items-center gap-2 px-4 py-2 bg-[#523680] text-white rounded-lg hover:bg-[#3f2a60] transition-colors"
        >
          {showCreateForm ? (
            <>
              <X size={18} />
              Cancel
            </>
          ) : (
            <>
              <Plus size={18} />
              Create Admin User
            </>
          )}
        </button>
      </div>

      {/* Create/Edit Form */}
      {showCreateForm && (
        <div className="bg-white p-6 rounded-lg shadow-md mb-6 border border-gray-200">
          <h2 className="text-xl font-semibold mb-4">
            {editingUser ? 'Edit Admin User' : 'Create New Admin User'}
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                disabled={!!editingUser}
                className={`w-full px-3 py-2 border ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                } rounded-md focus:outline-none focus:ring-2 focus:ring-[#523680]`}
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-500">{errors.name}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <div className="flex">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={!!editingUser}
                  className={`w-full px-3 py-2 border ${
                    errors.email ? 'border-red-500' : 'border-gray-300'
                  } rounded-l-md focus:outline-none focus:ring-2 focus:ring-[#523680]`}
                />
                {!editingUser && (
                  <button
                    type="button"
                    onClick={handleCheckPermissions}
                    disabled={checkingPermissions}
                    className="px-3 py-2 bg-gray-100 text-gray-700 border border-gray-300 rounded-r-md hover:bg-gray-200 focus:outline-none"
                  >
                    {checkingPermissions ? 'Checking...' : 'Check Permissions'}
                  </button>
                )}
              </div>
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            {!editingUser && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border ${
                      errors.password ? 'border-red-500' : 'border-gray-300'
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-[#523680]`}
                  />
                  {errors.password && (
                    <p className="mt-1 text-sm text-red-500">{errors.password}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="number"
                    value={formData.number}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border ${
                      errors.number ? 'border-red-500' : 'border-gray-300'
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-[#523680]`}
                  />
                  {errors.number && (
                    <p className="mt-1 text-sm text-red-500">{errors.number}</p>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Permissions
            </label>
            
            {errors.permissions && (
              <p className="mb-2 text-sm text-red-500">{errors.permissions}</p>
            )}
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
              {availablePermissions.map((permission) => (
                <div 
                  key={permission.path}
                  className={`flex items-center p-3 border rounded-lg cursor-pointer transition-all ${
                    formData.permissions.includes(permission.path)
                      ? 'border-[#523680] bg-[#f5f3fa]'
                      : 'border-gray-200 hover:border-gray-400'
                  }`}
                  onClick={() => handlePermissionToggle(permission.path)}
                >
                  <div className="mr-2">
                    {formData.permissions.includes(permission.path) ? (
                      <CheckCircle className="w-5 h-5 text-[#523680]" />
                    ) : (
                      <div className="w-5 h-5 rounded-full border border-gray-300" />
                    )}
                  </div>
                  <span className="text-sm">{permission.label}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={resetForm}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={editingUser ? handleUpdate : handleCreate}
              className="flex items-center gap-2 px-4 py-2 bg-[#523680] text-white rounded-md hover:bg-[#3f2a60]"
            >
              <Save size={18} />
              {editingUser ? 'Update Permissions' : 'Create Admin User'}
            </button>
          </div>
        </div>
      )}

      {/* Users List */}
      <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Permissions
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created By
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created Date
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan={7} className="px-6 py-4 text-center text-sm text-gray-500">
                    Loading admin users...
                  </td>
                </tr>
              ) : filteredAdminUsers.length === 0 ? (
                <tr>
                  <td colSpan={7} className="px-6 py-4 text-center text-sm text-gray-500">
                    No admin users found
                  </td>
                </tr>
              ) : (
                filteredAdminUsers.map((user) => (
                  <tr key={user._id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {user.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {user.active ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          <CheckCircle className="w-3 h-3 mr-1" />
                          Active
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          <XCircle className="w-3 h-3 mr-1" />
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex flex-wrap gap-1">
                        {user.permissions.length > 0 ? (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            {user.permissions.length} permissions
                          </span>
                        ) : (
                          <span className="text-gray-400">No permissions</span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.createdBy || 'System'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(user.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() => handleEditClick(user)}
                          className="text-indigo-600 hover:text-indigo-900"
                          title="Edit permissions"
                        >
                          <Edit className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => setDeleteDialog({ open: true, userId: user._id })}
                          className="text-red-600 hover:text-red-900"
                          title="Delete admin user"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, userId: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this admin user? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialog({ open: false, userId: null })}
            color="primary"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDelete}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Roles;