// import { useState, useEffect } from "react";
// import axios from "axios";

// const InvestmentHistory = () => {
//   const [investmentData, setInvestmentData] = useState<any[]>([]);
//   const [searchTerm, setSearchTerm] = useState(''); // State for the search term

//   useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     axios
//       .get(`https://api.growwincapital.com/api/invest/getInvestallhistory`, { headers })
//       .then((response) => {
//         console.log(response.data.Referal);
//         const investment: any = Object.values(response.data.Invest);
//         setInvestmentData(investment);
//       })
//       .catch((error) => {
//         console.error("Error fetching investment data:", error);
//       });
//   }, []);

//   // Function to handle search term changes
//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filter investmentData based on the search term
//   const filteredInvestmentData = investmentData.filter((item: any) =>
//     item.email.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div>
//       <h1 className="text-2xl font-bold mb-4">Investment History</h1>
//       <input
//         type="text"
//         placeholder="Search by email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />

//       <div className="h-[690px] overflow-scroll">
//         <table>
//           <thead>
//             <tr>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Total Investment</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Subscription</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Invested Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action Date</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Type</th>
//               <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredInvestmentData.reverse().map((investment: any, index: number) => (
//               <tr key={index + 1}>
//                 <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{investment.email}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{investment.action}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{Number(investment.totalAmount).toFixed(2)}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{investment.subscription}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//                   {investment.incomeDate ? new Date(investment.investDate).toLocaleDateString('en-GB', {
//                     day: '2-digit',
//                     month: 'short',
//                     year: 'numeric',
//                   }).replace(/ /g, '/')
//                   : 'N/A'}
//                 </td>
//                 <td className="py-3 px-8 border-b border-gray-200">
//                   {investment.actionDate ? new Date(investment.actionDate).toLocaleDateString('en-GB', {
//                     day: '2-digit',
//                     month: 'short',
//                     year: 'numeric',
//                   }).replace(/ /g, '/')
//                   : 'N/A'}
//                 </td>
//                 <td className="py-3 px-8 border-b border-gray-200">{investment.type}</td>
//                 <td className="py-3 px-8 border-b border-gray-200">{investment.amount}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default InvestmentHistory;


import { useState, useEffect } from "react";
import axios from "axios";

const InvestmentHistory = () => {
  const [investmentData, setInvestmentData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get(`https://api.growwincapital.com/api/invest/getInvestallhistory`)
      .then((response) => {
        const sortedData = Object.values(response.data.Invest)
          .filter((item: any) => item.createdAt) // Ensure createdAt exists
          .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()); // Sort newest first
  
        setInvestmentData(sortedData);
      })
      .catch((error) => console.error("Error fetching investment data:", error));
  }, []);
  

  // Function to handle search term changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Investment History</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by Email..."
        className="border rounded-lg p-3 w-full mb-6 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {/* Investment List */}
      <div className="space-y-6">
  {investmentData
    .filter((investment) => investment?.email?.toLowerCase()?.includes(searchTerm.toLowerCase()))
    .map((investment, index) => (
      <div key={index} className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 w-full">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold text-gray-800">{investment.email || "N/A"}</h2>
          <span
            className={`px-3 py-1 rounded-full text-xs font-semibold ${
              investment.type?.toLowerCase() === "credit"
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {investment.type === "credit" ? "Deposit" : "Withdrawal"}
          </span>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-3">
          <p className="text-gray-600"><strong>Action:</strong> {investment.action || "N/A"}</p>
          <p className="text-gray-600"><strong>Subscription:</strong> {investment.subscription || "N/A"}</p>
          <p className="text-gray-600"><strong>Investment Amount:</strong> ${Number(investment.totalAmount || 0).toFixed(2)}</p>
          <p className="text-gray-600">
  <strong>Invested Date:</strong>{" "}
  {investment.investDate && investment.investDate !== "Invalid Date"
    ? new Date(investment.investDate).toLocaleDateString("en-GB")
    : "N/A"}
</p>

          <p className="text-gray-600"><strong>Action Date:</strong> {investment.actionDate ? new Date(investment.actionDate).toLocaleDateString("en-GB") : "N/A"}</p>
          <p className="text-gray-600"><strong>Amount:</strong> ${Number(investment.amount || 0).toFixed(2)}</p>
        </div>
      </div>
    ))}
</div>

    </div>
  );
};

export default InvestmentHistory;
