import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface VersionData {
  platform: 'android' | 'ios';
  version: string;
  minRequiredVersion: string;
  updateUrl: string;
  updateMessage: string;
  isForceUpdate: boolean;
}

const AppVersionManagement: React.FC = () => {
  const [versions, setVersions] = useState<VersionData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  
  // Form state
  const [formData, setFormData] = useState<VersionData>({
    platform: 'android',
    version: '',
    minRequiredVersion: '',
    updateUrl: '',
    updateMessage: 'Please update to the latest version to continue using the app.',
    isForceUpdate: false
  });
  
  // Form validation
  const [formErrors, setFormErrors] = useState<{[key: string]: string}>({});

  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.growwincapital.com/api/appVersion/versions', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('admin_token')}`
        }
      });
      
      if (response.data.success) {
        setVersions(response.data.data);
      }
    } catch (err) {
      setError('Failed to fetch version data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: {[key: string]: string} = {};
    const semverRegex = /^\d+\.\d+\.\d+$/;
    
    if (!formData.version) {
      errors.version = 'Version is required';
    } else if (!semverRegex.test(formData.version)) {
      errors.version = 'Version must be in format x.y.z';
    }
    
    if (!formData.minRequiredVersion) {
      errors.minRequiredVersion = 'Minimum version is required';
    } else if (!semverRegex.test(formData.minRequiredVersion)) {
      errors.minRequiredVersion = 'Version must be in format x.y.z';
    }
    
    if (!formData.updateUrl) {
      errors.updateUrl = 'Update URL is required';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const checkbox = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checkbox.checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      setSuccessMessage(null);
      
      const response = await axios.post('https://api.growwincapital.com/api/appVersion/versions', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('admin_token')}`
        }
      });
      
      if (response.data.success) {
        setSuccessMessage(`Version updated successfully for ${formData.platform}`);
        fetchVersions();
      }
    } catch (err) {
      setError('Failed to update version');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      platform: 'android',
      version: '',
      minRequiredVersion: '',
      updateUrl: '',
      updateMessage: 'Please update to the latest version to continue using the app.',
      isForceUpdate: false
    });
    setFormErrors({});
  };

  const editVersion = (version: VersionData) => {
    setFormData({
      platform: version.platform,
      version: version.version,
      minRequiredVersion: version.minRequiredVersion,
      updateUrl: version.updateUrl,
      updateMessage: version.updateMessage,
      isForceUpdate: version.isForceUpdate
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">App Version Management</h1>
      
      {error && <div className="bg-red-100 p-4 mb-4 rounded text-red-700">{error}</div>}
      {successMessage && <div className="bg-green-100 p-4 mb-4 rounded text-green-700">{successMessage}</div>}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 className="text-xl font-semibold mb-4">Update Version</h2>
          <form onSubmit={handleSubmit} className="space-y-4 p-4 border rounded bg-white">
            <div>
              <label className="block mb-1">Platform</label>
              <select
                name="platform"
                value={formData.platform}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              >
                <option value="android">Android</option>
                <option value="ios">iOS</option>
              </select>
            </div>
            
            <div>
              <label className="block mb-1">Current Version (x.y.z)</label>
              <input
                type="text"
                name="version"
                value={formData.version}
                onChange={handleInputChange}
                placeholder="1.0.0"
                className="w-full p-2 border rounded"
              />
              {formErrors.version && <p className="text-red-500 text-sm">{formErrors.version}</p>}
            </div>
            
            <div>
              <label className="block mb-1">Minimum Required Version (x.y.z)</label>
              <input
                type="text"
                name="minRequiredVersion"
                value={formData.minRequiredVersion}
                onChange={handleInputChange}
                placeholder="1.0.0"
                className="w-full p-2 border rounded"
              />
              {formErrors.minRequiredVersion && <p className="text-red-500 text-sm">{formErrors.minRequiredVersion}</p>}
            </div>
            
            <div>
              <label className="block mb-1">Update URL</label>
              <input
                type="text"
                name="updateUrl"
                value={formData.updateUrl}
                onChange={handleInputChange}
                placeholder="https://play.google.com/store/apps/details?id=com.yourapp"
                className="w-full p-2 border rounded"
              />
              {formErrors.updateUrl && <p className="text-red-500 text-sm">{formErrors.updateUrl}</p>}
            </div>
            
            <div>
              <label className="block mb-1">Update Message</label>
              <textarea
                name="updateMessage"
                value={formData.updateMessage}
                onChange={handleInputChange}
                placeholder="Please update to the latest version to continue using the app."
                className="w-full p-2 border rounded"
                rows={3}
              ></textarea>
            </div>
            
            <div className="flex items-center">
              <input
                type="checkbox"
                id="isForceUpdate"
                name="isForceUpdate"
                checked={formData.isForceUpdate}
                onChange={handleInputChange}
                className="mr-2"
              />
              <label htmlFor="isForceUpdate">Force Update</label>
            </div>
            
            <div className="flex space-x-4">
              <button
                type="submit"
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Version'}
              </button>
              
              <button
                type="button"
                onClick={resetForm}
                className="bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
        
        <div>
          <h2 className="text-xl font-semibold mb-4">Current Versions</h2>
          
          {loading ? (
            <p>Loading...</p>
          ) : versions.length > 0 ? (
            <div className="space-y-4">
              {versions.map((version, index) => (
                <div key={index} className="p-4 border rounded bg-white">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold capitalize">{version.platform}</span>
                    <button
                      onClick={() => editVersion(version)}
                      className="text-blue-600 hover:underline"
                    >
                      Edit
                    </button>
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>
                      <span className="font-medium">Current Version:</span> {version.version}
                    </div>
                    <div>
                      <span className="font-medium">Min Required:</span> {version.minRequiredVersion}
                    </div>
                    <div className="col-span-2">
                      <span className="font-medium">Update URL:</span>{' '}
                      <a href={version.updateUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline truncate block">
                        {version.updateUrl}
                      </a>
                    </div>
                    <div className="col-span-2">
                      <span className="font-medium">Force Update:</span>{' '}
                      {version.isForceUpdate ? 'Yes' : 'No'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No version data available. Please add a version.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppVersionManagement;