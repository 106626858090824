


import React, { useState, useEffect } from "react";
import axios from "axios";

type User = {
  _id: string;
  name: string;
  email: string;
  number: string;
  refree: string;
  createdAt: string;
  active: boolean;
  investmentAllowed: boolean;
};

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [referredBy, setReferredBy] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const userResponse = await axios.get("https://api.growwincapital.com/api/user/getUsers/all");
      const userData = userResponse.data;

      if (userData.status === "success" && userData.user) {
        const usersArray: User[] = Object.values(userData.user);
        setUsers(usersArray.reverse());
        fetchReferredUsers(usersArray);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // ✅ Optimized: Batch fetch referred users
  const fetchReferredUsers = async (usersArray: User[]) => {
    const referredByMap: Record<string, string> = {};

    const batchSize = 10;
    for (let i = 0; i < usersArray.length; i += batchSize) {
      const batch = usersArray.slice(i, i + batchSize);

      await Promise.all(
        batch.map(async (user) => {
          try {
            const refereeResponse = await axios.get(
              `https://api.growwincapital.com/api/referal/getReferal/${user.refree}`
            );
            referredByMap[user.email] = refereeResponse.data?.email?.email || "Not Referred";
          } catch {
            referredByMap[user.email] = "Not Referred";
          }
        })
      );
    }

    setReferredBy(referredByMap);
  };

  const toggleActiveStatus = async (email: string) => {
    try {
      const user = users.find((user) => user.email === email);
      if (!user) return;

      const isActive = !user.active;
      await axios.put("https://api.growwincapital.com/api/user/toggleActiveStatusByEmail", {
        email,
        active: isActive,
      });

      setUsers((prev) =>
        prev.map((user) => (user.email === email ? { ...user, active: isActive } : user))
      );
    } catch (error) {
      console.error("Failed to toggle active status:", error);
    }
  };

  const toggleInvestmentStatus = async (email: string) => {
    try {
      const user = users.find((user) => user.email === email);
      if (!user) return;

      const isAllowed = !user.investmentAllowed;
      await axios.put(`https://api.growwincapital.com/api/invest/toggle-investment-allowed/${email}`);

      setUsers((prev) =>
        prev.map((user) => (user.email === email ? { ...user, investmentAllowed: isAllowed } : user))
      );
    } catch (error) {
      console.error("Failed to toggle investment status:", error);
    }
  };

  // ✅ Filter logic based on selected filter option
  const filteredUsers = users.filter((user) => {
    if (
      searchTerm &&
      !(
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (referredBy[user.email]?.toLowerCase() || "").includes(searchTerm.toLowerCase())
      )
    ) {
      return false;
    }

    switch (filter) {
      case "active":
        return user.active;
      case "inactive":
        return !user.active;
      case "active-invest":
        return user.active && user.investmentAllowed;
      case "inactive-invest":
        return user.active && !user.investmentAllowed;
      default:
        return true; // Show all
    }
  });

  return (
    <div className="max-w-5xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">User Management</h1>

      {/* Search & Filter Section */}
      <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
        <input
          type="text"
          placeholder="Search by email, name, or referrer..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full md:w-2/3 p-3 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
        />

        {/* Dropdown Filter */}
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="p-3 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
        >
          <option value="all">All Users ({users.length})</option>
          <option value="active">Active Users ({users.filter((u) => u.active).length})</option>
          <option value="inactive">Inactive Users ({users.filter((u) => !u.active).length})</option>
          <option value="active-invest">
            Active & Invest Allowed ({users.filter((u) => u.active && u.investmentAllowed).length})
          </option>
          <option value="inactive-invest">
            Active & Invest Stopped ({users.filter((u) => u.active && !u.investmentAllowed).length})
          </option>
        </select>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="text-center text-gray-500">Loading users...</div>
      ) : filteredUsers.length === 0 ? (
        <div className="text-center text-gray-500">No users found.</div>
      ) : (
        <div className="space-y-4">
          {filteredUsers.map((user, index) => (
            <div
              key={user._id}
              className="bg-white shadow-md rounded-lg p-5 border border-gray-200 hover:shadow-lg transition duration-300"
            >
              <div className="flex justify-between">
                <div>
                  <h2 className="text-lg font-semibold text-gray-800">{user.name}</h2>
                  <p className="text-gray-600">
                    <strong>Email:</strong> {user.email}
                  </p>
                  <p className="text-gray-600">
                    <strong>Phone:</strong> {user.number}
                  </p>
                  <p className="text-gray-600">
                    <strong>Referred By:</strong> {referredBy[user.email] || "Loading..."}
                  </p>
                  <p className="text-gray-600">
                    <strong>Created:</strong>{" "}
                    {new Date(user.createdAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                </div>

                {/* Status Buttons */}
                <div className="flex flex-col items-end space-y-2">
                  <button
                    onClick={() => toggleActiveStatus(user.email)}
                    className={`px-4 py-2 text-white rounded-lg ${
                      user.active ? "bg-green-500 hover:bg-green-600" : "bg-red-500 hover:bg-red-600"
                    }`}
                  >
                    {user.active ? "Active" : "Inactive"}
                  </button>

                  <button
                    onClick={() => toggleInvestmentStatus(user.email)}
                    className={`px-4 py-2 text-white rounded-lg ${
                      user.investmentAllowed ? "bg-green-500 hover:bg-green-600" : "bg-red-500 hover:bg-red-600"
                    }`}
                  >
                    {user.investmentAllowed ? "Investment On" : "Investment Off"}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Users;
